import { Box, Button, HStack, Text } from '@chakra-ui/react';
import { DropzoneState } from 'react-dropzone';
import { COLORS } from 'constant';

export const CsvFileUpload = ({
  csvFile,
  removeFile,
  dropzone,
}: {
  csvFile: File | null;
  removeFile: () => void;
  dropzone: DropzoneState;
}) => {
  const { getRootProps, getInputProps } = dropzone;

  return (
    <Box
      {...(csvFile ? {} : getRootProps())}
      display="flex"
      alignItems="center"
      justifyContent={csvFile ? 'flex-start' : 'center'}
      border="1px dashed"
      bg={COLORS.GRAY.GRAY_200}
      borderColor={COLORS.GRAY.GRAY_400}
      w="full"
      borderRadius="md"
      cursor={csvFile ? 'default' : 'pointer'}
    >
      {csvFile ? (
        <HStack flex="1" p="10px" justifyContent="space-between" w="full">
          <Text
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            flexBasis="85%"
            fontSize="14px"
            color={COLORS.GRAY.GRAY_600}
          >
            {csvFile.name}
          </Text>
          <Button
            flexBasis="15%"
            variant="link"
            size="xs"
            color={COLORS.FINCH.PURPLE}
            textDecor="underline"
            onClick={removeFile}
            _hover={{ color: COLORS.FINCH.BLACK }}
          >
            Update
          </Button>
        </HStack>
      ) : (
        <>
          <input {...getInputProps()} />
          <Text fontSize="14px" color={COLORS.GRAY.GRAY_600} py="50px">
            Drag a CSV file here or click to upload
          </Text>
        </>
      )}
    </Box>
  );
};
