export function CancelXIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.0627 15.1596L4.84082 13.9377L8.77832 10.0002L4.84082 6.0627L6.0627 4.84082L10.0002 8.77832L13.9377 4.84082L15.1596 6.0627L11.2221 10.0002L15.1596 13.9377L13.9377 15.1596L10.0002 11.2221L6.0627 15.1596Z"
        fill="#999999"
      />
    </svg>
  );
}
