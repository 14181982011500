import { Stack } from '@chakra-ui/react';

import { UseFormReturn } from 'react-hook-form';
import { FormInput } from 'components/FormInput';
import { FormSelect } from 'components/FormSelect';
import { NewSession } from 'connections-v2/hooks/useConnectSession';
import { useParams } from 'react-router-dom';
import startCase from 'lodash/startCase';
import { PRODUCT_LABEL_MAP } from 'constant/scopes';
import { useApplication } from 'applications/hooks';

export const RequiredSection = ({
  initialRef,
  form,
}: {
  initialRef: React.RefObject<HTMLInputElement> | undefined;
  form: UseFormReturn<NewSession>;
}) => {
  const { applicationId } = useParams<{ applicationId: string }>();
  const { application } = useApplication(applicationId);

  const { register, formState } = form;

  const allScopes =
    application?.scopes
      .filter(({ name }) => name && PRODUCT_LABEL_MAP.get(name))
      .map(({ name }) => ({
        value: `employer:${name}`,
        label: PRODUCT_LABEL_MAP.get(name) || startCase(name),
      })) || [];
  const selectedScopes = form.watch('scopes', []).map((scope) => ({
    value: scope,
    label:
      PRODUCT_LABEL_MAP.get(scope.replace('employer:', '')) ||
      startCase(scope.replace('employer:', '')),
  }));

  return (
    <Stack spacing={6} fontSize="14px">
      <Stack spacing={4}>
        <FormInput
          id="externalCompanyName"
          label="Customer Name"
          register={register}
          inputRef={initialRef}
          helpText="This represents your end user or company's name. It must be a unique identifier across all active connections for your application."
          error={formState.errors.externalCompanyName?.message}
        />
        <FormInput
          id="externalCompanyId"
          register={register}
          error={formState.errors.externalCompanyId?.message}
          label="Customer ID"
          helpText="This represents your end user or their company. It must be a unique identifier across all active connections for your application."
        />
        <FormSelect
          id="scopes"
          register={register}
          isMulti
          control={form.control}
          placeholder="Choose products"
          options={allScopes}
          isClearable={false}
          label="Products"
          helpText="Choose which Finch products to be included as part of the Finch Connect flow."
          defaultValue={selectedScopes.length ? selectedScopes : allScopes}
        />
      </Stack>
    </Stack>
  );
};
