import { create } from 'zustand';
import { Category } from '../types';

export const usePayStatementItemStore = create<{
  clear: () => void;

  editing: boolean;
  setEditing: (polarity: boolean) => void;

  showingSince: Date;
  setShowingSince: (date: Date) => void;
  resetShowingSince: () => void;

  eligible: Partial<Record<Category, Record<string, boolean>>>;
  setEligible: (category: Category, item: string, polarity: boolean) => void;

  labels: Partial<Record<Category, Record<string, string | null | undefined>>>;
  setLabel: (category: Category, item: string, label?: string | null) => void;
}>((set) => ({
  clear: () => set({ eligible: {}, labels: {} }),

  editing: false,
  setEditing: (polarity) => set({ editing: polarity }),

  showingSince: new Date(new Date().setFullYear(2024, 0, 1)),
  setShowingSince: (showingSince) => {
    set({ showingSince });
  },
  resetShowingSince: () => {
    set({ showingSince: new Date(new Date().setFullYear(2024, 0, 1)) });
  },

  eligible: {},
  setEligible: (category, item, polarity) =>
    set((previous) => {
      return {
        ...previous,
        eligible: {
          ...previous.eligible,
          [category]: {
            ...previous.eligible[category],
            [item]: polarity,
          },
        },
      };
    }),

  labels: {},
  setLabel: (category, item, label) =>
    set((previous) => {
      return {
        ...previous,
        labels: {
          ...previous.labels,
          [category]: {
            ...previous.labels[category],
            [item]: label,
          },
        },
      };
    }),
}));
