import { Button, ModalFooter, ModalBody } from '@chakra-ui/react';
import { RefObject } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { OptionalSection } from './OptionalSection';
import { RequiredSection } from './RequiredSection';
import { useProviderConfigurations } from 'integrations/hooks/useProviderConfigurations';
import {
  CreateConnectSessionResponse,
  CreateConnectSessionError,
  NewSession,
  ReauthSession,
} from 'connections-v2/hooks/useConnectSession';
import { AxiosError } from 'axios';
import { UseMutateFunction } from '@tanstack/react-query';

export const SingleLinkForm = ({
  singleLinkFormSection,
  initialRef,
  form,
  isPending,
  setSingleLinkFormSection,
  mutate,
}: {
  singleLinkFormSection: 'required' | 'optional';
  initialRef?: RefObject<HTMLInputElement>;
  form: UseFormReturn<NewSession>;
  isPending: boolean;
  setSingleLinkFormSection: (section: 'required' | 'optional') => void;
  mutate: UseMutateFunction<
    CreateConnectSessionResponse,
    AxiosError<CreateConnectSessionError>,
    NewSession | ReauthSession,
    unknown
  >;
}) => {
  const { handleSubmit, formState } = form;

  const { automatedProviders, isLoading, allProviders } =
    useProviderConfigurations();

  return (
    <>
      <ModalBody zIndex={1} px="0" pt="6">
        {singleLinkFormSection === 'required' && (
          <RequiredSection initialRef={initialRef} form={form} />
        )}
        {singleLinkFormSection === 'optional' && (
          <OptionalSection
            allProviders={allProviders}
            automatedProviders={automatedProviders}
            isLoading={isLoading}
            form={form}
          />
        )}
      </ModalBody>
      <ModalFooter px="0" gap="8px">
        {singleLinkFormSection === 'optional' && (
          <Button
            variant="secondary"
            isDisabled={!formState.isValid}
            isLoading={isPending}
            fontSize="16px"
            onClick={() => setSingleLinkFormSection('required')}
            h="52px"
          >
            {'<-'}
          </Button>
        )}
        <Button
          w="full"
          variant="primary"
          isDisabled={!formState.isValid}
          isLoading={isPending}
          fontSize="16px"
          onClick={
            singleLinkFormSection === 'required'
              ? () => setSingleLinkFormSection('optional')
              : handleSubmit((values) => mutate(values))
          }
          h="52px"
        >
          {singleLinkFormSection === 'required'
            ? 'Next Step ->'
            : 'Generate URL ->'}
        </Button>
      </ModalFooter>
    </>
  );
};
