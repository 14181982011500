import { useCallback, useEffect, useMemo, useState } from 'react';
import { Flex, HStack, Spacer, Stack } from '@chakra-ui/react';
import isArray from 'lodash/isArray';

import { Category, Item, Label } from '../types';
import { PayStatementCategoryGroup } from '../components/pay-statement-category-group';
import { EmptyState } from '../../../../components/EmptyState';
import { ContentBox } from '../../../../components/ContentBox';
import { useLabels } from '../hooks/use-labels';
import { useEligibility } from '../hooks/use-eligibility';
import { PayStatementCategoryEditControls } from '../components/pay-statement-category-edit-controls';
import { useFormControls } from '../hooks/use-form-controls';
import { exportCSV } from '../../../../utils/exportCsv';
import { categoryOrder } from '../constants';
import { Loading } from '../../../../components/Loading/Loading';
import { PayStatementCategorySelector } from '../components/pay-statement-category-selector';

const EmptyJobHistoryIcon = () => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="64" height="64" rx="32" fill="#EFF1FF" />
      <path
        d="M23.4166 47L15.3333 38.9167L23.4445 30.8054L25.4028 32.75L20.6249 37.5279H46.9999V40.3054H20.6249L25.3749 45.0554L23.4166 47ZM40.5833 33.1946L38.6249 31.25L43.3749 26.5H16.9999V23.7221H43.3749L38.597 18.9446L40.5553 17L48.6666 25.1112L40.5833 33.1946Z"
        fill="#3E4AE7"
      />
    </svg>
  );
};

function PayStatementItems({
  selectedFilter,
  editing,
  loading,
  categories,
  payStatementItem,
  selectedCategories,
  payStatementItemLabels,
}: {
  selectedFilter?: string;
  loading: boolean;
  editing: boolean;
  categories: Map<Category, Item[]>;
  payStatementItem: Item[];
  payStatementItemLabels: Label[];
  selectedCategories: Category[];
}) {
  const { setLabel, labels } = useLabels();
  const { setEligible, eligible } = useEligibility();
  const onChange = useCallback(
    (item: Item, label: string | string[]) => {
      const selected = isArray(label) ? label[0] : label;
      const option = payStatementItemLabels.find((x) => x.value === selected);
      const value = option?.value;

      setLabel(item.category, item.name, value ?? null);
    },
    [setLabel, payStatementItemLabels],
  );
  const onToggle = useCallback(
    (item: Item, polarity: boolean) => {
      setEligible(item.category, item.name, polarity);
    },
    [setEligible],
  );
  const mergedLabels = useMemo(() => {
    const serverStateLabels = payStatementItem.reduce((merged, item) => {
      const stagedLabel = labels[item.category]?.[item.name];
      let value = stagedLabel ?? item.attributes.metadata?._PENSION_PRO_COLUMN;

      if (stagedLabel === null) {
        value = undefined;
      }

      return {
        ...merged,
        [item.category]: {
          ...merged[item.category],
          [item.name]: value,
        },
      };
    }, {} as Partial<Record<Category, Record<string, string>>>);

    return { ...labels, ...serverStateLabels };
  }, [payStatementItem, labels]);

  const mergedEligible = useMemo(() => {
    const serverStateEligibility = payStatementItem.reduce<
      Partial<Record<Category, Record<string, boolean>>>
    >((merged, item) => {
      const stagedEligibility = eligible[item.category]?.[item.name];

      return {
        ...merged,
        [item.category]: {
          ...merged[item.category],
          [item.name]:
            stagedEligibility ??
            item.attributes.metadata?._RETIREMENT_PLAN_ELIGIBILITY,
        },
      };
    }, {});

    return { ...eligible, ...serverStateEligibility };
  }, [payStatementItem, eligible]);

  const generateItemsToRender = (_name: Category) =>
    (categories.get(_name) ?? []).filter((e) => {
      if (selectedFilter === 'all_items') {
        return true;
      } else if (selectedFilter === 'mapping_required') {
        return false;
      } else if (selectedFilter === 'all_mapped') {
        return (
          e.attributes.metadata && e.attributes.metadata._PENSION_PRO_COLUMN
        );
      } else if (selectedFilter === 'all_unmapped') {
        return (
          !e.attributes.metadata || !e.attributes.metadata._PENSION_PRO_COLUMN
        );
      }

      return false;
    });

  if (loading) {
    return (
      <ContentBox py="16px">
        <Loading
          message="We're looking for you pay statement items"
          omitBorder
        />
      </ContentBox>
    );
  }

  if (categories.size === 0) {
    return (
      <ContentBox py="64px">
        <EmptyState
          icon={<EmptyJobHistoryIcon />}
          text="There's nothing here just yet."
          subText="Pay statement items are not yet available"
        />
      </ContentBox>
    );
  }

  const options: { key: string; value: string | undefined }[] = [
    { key: 'none', value: 'None' },
    ...payStatementItemLabels,
  ];

  return (
    <>
      {categoryOrder.map((name, i) => {
        if (
          selectedCategories.length > 0 &&
          (!categories.has(name) || !selectedCategories.includes(name))
        ) {
          return null;
        }

        const itemsToRender = generateItemsToRender(name);

        if (itemsToRender.length === 0) {
          return null;
        }

        return (
          <PayStatementCategoryGroup
            key={i}
            editing={editing}
            header={name}
            items={itemsToRender}
            labels={options}
            values={mergedLabels}
            checked={mergedEligible}
            disabled={loading}
            onChange={onChange}
            onToggle={onToggle}
          />
        );
      })}
    </>
  );
}

export type FilterType =
  | 'all_items'
  | 'mapping_required'
  | 'all_mapped'
  | 'all_unmapped';

export function PayStatementMapping({
  payStatementItem,
  payStatementItemLabels,
  isPayStatementsLoading,
  enableControls = true,
}: {
  payStatementItem: Item[];
  payStatementItemLabels: Label[];
  isPayStatementsLoading: boolean;
  enableControls?: boolean;
}) {
  const { setLabel, labels } = useLabels();
  const [selectedFilter, setSelectedFilter] = useState<FilterType | undefined>(
    'all_items',
  );
  const {
    loading,
    editing,
    onEdit,
    onCancel,
    onSave,
    showingSince,
    setShowingSince,
    resetShowingSince,
  } = useFormControls(payStatementItem);

  useEffect(
    () => () => {
      resetShowingSince();
      onCancel();
    },
    [],
  );

  const categories = useMemo(
    () =>
      payStatementItem.reduce((categories, item) => {
        // TEMP: we are cutting scope on taxes for P1
        // https://tryfinch.slack.com/archives/C07PY2U4MAP/p1732903918909129
        if (item.category === 'taxes') {
          return categories;
        }

        if (!categories.has(item.category)) {
          categories.set(item.category, []);
        }

        categories.get(item.category)?.push(item);

        return categories;
      }, new Map<Category, Item[]>()),
    [payStatementItem],
  );
  const download = useCallback(() => {
    const data = Array.from(categories.entries()).reduce(
      (rows, [category, items]) => {
        for (const item of items) {
          const label =
            item.attributes.metadata !== null
              ? item.attributes.metadata?._PENSION_PRO_COLUMN ?? ''
              : '';
          const eligible =
            item.attributes !== null
              ? item.attributes.metadata?._RETIREMENT_PLAN_ELIGIBILITY
              : undefined;

          rows.push({
            category,
            name: item.name,
            label,
            eligible: eligible === undefined ? '' : `${eligible}`,
          });
        }

        return rows;
      },
      new Array<{
        category: Category;
        name: string;
        label: string;
        eligible: string;
      }>(),
    );

    exportCSV({
      data,
      headers: ['category', 'name', 'label', 'eligible'],
      fileName: 'Pay-Statement-Item-Mapping-' + new Date().toISOString(),
    });
  }, [categories]);
  const remapping = useMemo(() => {
    for (const key in labels) {
      const category = key as Category;

      if (categories.has(category)) {
        const mapped = categories.get(category)?.map(({ name }) => name);
        const remapped = Object.keys(labels[category] ?? {});

        for (const name of remapped) {
          if (mapped?.includes(name)) {
            return true;
          }
        }
      }
    }

    return false;
  }, [categories, labels]);
  const isLoading = loading || isPayStatementsLoading;

  const [selectedCategories, setSelectedCategories] = useState<Category[]>(
    Array.from(categories.keys()) as Category[],
  );

  useEffect(() => {
    if (selectedFilter === 'all_items') {
      setSelectedCategories([]);
    }
  }, [selectedFilter]);

  return (
    <Stack gap="24px" marginTop="8px">
      {enableControls ? (
        <HStack>
          <Spacer />
          <PayStatementCategoryEditControls
            remapping={remapping}
            download={download}
            disabled={isLoading}
            editing={editing}
            showingSince={showingSince}
            setShowingSince={setShowingSince}
            setEditing={onEdit}
            saveEdits={onSave}
            cancelEdits={onCancel}
          />
        </HStack>
      ) : null}
      <Flex direction={'row'} gap={4}>
        <PayStatementCategorySelector
          categories={categories}
          selectedCategories={selectedCategories}
          setSelectedCategories={setSelectedCategories}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
        />
        <Stack w={'100%'}>
          <PayStatementItems
            selectedCategories={selectedCategories}
            categories={categories}
            editing={editing}
            loading={isPayStatementsLoading}
            payStatementItem={payStatementItem}
            payStatementItemLabels={payStatementItemLabels}
            selectedFilter={selectedFilter}
          />
        </Stack>
      </Flex>
    </Stack>
  );
}
