import { Stack } from '@chakra-ui/react';
import 'react-calendar/dist/Calendar.css';
import ReactDatePicker from 'react-datepicker';
import { CalendarContainer } from '../../../../connections/components/DatePickerTheme';

export const PayStatementInputCalendar = (props: {
  value: string;
  onChange: (value: Date) => void;
}) => {
  return (
    <Stack>
      <style>
        {`
        .react-datepicker {
          width: 100% !important;
          border-top-right-radius: 0px;
          border-top-left-radius: 0px;
          position: relative;
          border: 1px solid #D9D9D9;
          border-top: 0px;
          bottom: 4px;
        }
        .react-datepicker__navigation {
          top: 20px !important;
        }
        .react-datepicker__navigation--next {
          right: 10px !important;
        }
        .react-datepicker__navigation--previous {
          left: 10px !important;
        }
        .react-datepicker__month-container {
          width: 100% !important;
          padding: 10px;
        }
        .react-datepicker__day--keyboard-selected {
          border-radius: 20px !important;
          background: #EFF1FF !important;
          color: #3E4AE7 !important;
          font-weight: 600 !important;
        }
        .react-datepicker__day--selected {
          border-radius: 20px !important;
          background: #EFF1FF !important;
          color: #3E4AE7 !important;
          font-weight: 600 !important;
        }
        .react-datepicker__day:hover {
          border-radius: 20px !important;
        }
        .react-datepicker__day-names {
          display: flex;
          padding: 0px 10px;
          justify-content: space-between;
        }
        .react-datepicker__day {
          max-width: 28px !important;
          max-height: 28px !important;
        }
        .react-datepicker__header {
          background: white;
          padding-bottom: 10px;
          border-bottom: 0px !important;
        }
        .react-datepicker__current-month {
          font-weight: 500 !important;
          padding-bottom: 15px;
        }
        .react-datepicker__day-name {
          width: fit-content;
        }
        .react-datepicker__week {
          display: flex;
          justify-content: space-between;
        }
        .react-datepicker__day {
          flex-grow: 1;
        }
      `}
      </style>
      <ReactDatePicker
        placeholderText="MM/DD/YYYY"
        dateFormat="MM/dd/yyyy"
        selected={props.value ? new Date(props.value) : undefined}
        value={props.value ? props.value : undefined}
        disabledKeyboardNavigation
        inline
        onChange={(date) => {
          const val = date ? new Date(date?.toLocaleString()) : undefined;

          if (val) {
            props.onChange(val);
          }
        }}
        calendarContainer={CalendarContainer}
      />
    </Stack>
  );
};
