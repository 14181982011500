import { useToast } from '@chakra-ui/react';
import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { OrganizationClient } from '../../auth/organization-client';

export const useOrganization = () => {
  const auth0 = useAuth0();
  const toast = useToast({
    duration: 3000,
    isClosable: true,
  });

  const organizationClient = new OrganizationClient(auth0);

  const members = useQuery({
    queryKey: ['organization', 'members'],
    queryFn: organizationClient.getOrganizationMembers,
    retry: 3,
  });

  const organization = useQuery({
    queryKey: ['organization'],
    queryFn: organizationClient.getOrganization,
  });

  const removeMember = useMutation({
    mutationFn: organizationClient.removeOrganizationMember,
    onSuccess: () => members.refetch(),
  });

  const inviteMember = useMutation({
    mutationFn: organizationClient.inviteOrganizationMember,
    onSuccess: () => members.refetch(),
  });

  const updateMfaSettings = useMutation({
    mutationFn: organizationClient.updateMfaSettings,
    onSuccess: () => {
      organization.refetch();
    },
    onError: () => {
      toast({
        description:
          'Error enforcing Multi-Factor Authentication for organization',
        status: 'error',
      });
    },
  });

  const getSsoConfigurations = useQuery({
    queryKey: ['ssoConfigurations'],
    queryFn: organizationClient.getSsoConfigurations,
  });

  const updateSsoConfiguration = useMutation({
    mutationFn: organizationClient.updateSsoConfigurations,
    onSuccess: () => {
      getSsoConfigurations.refetch();
      toast({
        description: 'Security settings have been successfully updated',
        status: 'success',
      });
    },
    onError: () => {
      toast({
        description: 'Error updating security settings',
        status: 'error',
      });
    },
  });

  const deleteSsoConfiguration = useMutation({
    mutationFn: organizationClient.deleteSsoConfiguration,
    onSuccess: () => {
      getSsoConfigurations.refetch();
      toast({
        description: 'Security settings have been successfully updated',
        status: 'success',
      });
    },
    onError: () => {
      toast({
        description: 'Error deleting SSO configuration',
        status: 'error',
      });
    },
  });

  return {
    members,
    removeMember,
    inviteMember,
    organization,
    updateMfaSettings,
    getSsoConfigurations,
    updateSsoConfiguration,
    deleteSsoConfiguration,
  };
};
