import { ReactNode, useState } from 'react';
import first from 'lodash/first';
import { JobStatusTable } from './JobsTable';
import { Flex, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import {
  ConnectionAccountDetail,
  ConnectionConfiguration,
  JobEntry,
  PayStatementItem,
  PayStatementItemLabel,
} from '../types';
import { AssistedSetupStatus } from './AssistedSetupStatus';
import {
  ConnectionDetailTab,
  connectionDetailTabs,
  SessionHistoryTab,
} from '../constants';
import { ConnectionOverviewPanel } from './ConnectionOverviewPanel';
import { isAssistedImplementationKind } from '@finch-api/common/dist/internal/connect/authorize';
import { AccountStatus } from '@finch-api/common/dist/external/dashboard/connection-status';
import { FeatureFlag } from '../../constant/feature-flags';
import { useFlag } from '@unleash/proxy-client-react';
import { ApiExplorer } from 'connection-detail-v2/pages/api-explorer/pages';
import { ConnectSessions } from './ConnectSessions';
import { AuthenticationProgressPanel } from './AuthenticationProgressPanel';
import { useConnectSession } from 'connections-v2/hooks/useConnectSession';
import { useConnectSessionEvents } from '../hooks/useConnectSessionEvents';
import { ConnectSession } from '@/types/connect';
import { AppTab, AppTabList } from 'components/Tabs';
import { isTabNew } from '../utils';
import { PayStatementMapping } from 'connection-detail-v2/pages/pay-statement-mapping/pages';

const AuthProgressPanelWithApi = ({
  navigateToSessionEventsTab,
}: {
  navigateToSessionEventsTab: (connectSession: ConnectSession) => void;
}) => {
  const { list } = useConnectSession();

  const latestSession = list.data?.[0];

  const { list: connectSessionEventsList } = useConnectSessionEvents({
    sessionId: latestSession?.id,
  });

  return (
    <AuthenticationProgressPanel
      connectSessionEvents={connectSessionEventsList.data}
      navigateToSessionEventsTab={() =>
        latestSession && navigateToSessionEventsTab(latestSession)
      }
    />
  );
};

type TabComponentProps = {
  connectionDetail: ConnectionAccountDetail;
  jobs: JobEntry[];
  payStatementItem: PayStatementItem;
  payStatementItemLabels: PayStatementItemLabel[];
  isPayStatementsLoading: boolean;
  statusLog?: ConnectionAccountDetail['statusTimeline'][number];
  assistedProduct?: ConnectionAccountDetail['products'][number];
  navigateToSessionEventsTab: (connectSession: ConnectSession) => void;
  selectedSession: ConnectSession | null;
  setSelectedSession: (connectSession: ConnectSession | null) => void;
  activeSessionEventsTab: SessionHistoryTab;
  setActiveSessionEventsTab: (tab: SessionHistoryTab) => void;
};

const TAB_TO_COMPONENT: Record<
  ConnectionDetailTab,
  (props: TabComponentProps) => ReactNode
> = {
  [ConnectionDetailTab.OVERVIEW]: ConnectionOverviewPanel,
  [ConnectionDetailTab.JOB_HISTORY]: JobStatusTable,
  [ConnectionDetailTab.PAY_STATEMENT_MAPPING]: PayStatementMapping,
  [ConnectionDetailTab.ASSISTED_SETUP_STATUS]: ({
    assistedProduct,
    statusLog,
  }: {
    assistedProduct?: ConnectionAccountDetail['products'][number];
    statusLog?: ConnectionAccountDetail['statusTimeline'][number];
  }) => (
    <AssistedSetupStatus
      status={assistedProduct?.status}
      message={assistedProduct?.statusMessage}
      lastUpdatedAt={statusLog ? new Date(statusLog?.createdAt) : undefined}
    />
  ),
  [ConnectionDetailTab.API_EXPLORER]: ApiExplorer,
  [ConnectionDetailTab.AUTHENTICATION_PROGRESS]: AuthProgressPanelWithApi,
  [ConnectionDetailTab.CONNECT_SESSIONS]: ConnectSessions,
};

const ConnectionDetailTabs = ({
  connectionConfiguration,
  connectionDetail,
  jobs,
  payStatementItem,
  payStatementItemLabels,
  isPayStatementsLoading,
  selectedTab,
  setSelectedTab,
}: {
  connectionConfiguration: ConnectionConfiguration;
  connectionDetail: ConnectionAccountDetail;
  jobs: JobEntry[];
  payStatementItem: PayStatementItem;
  payStatementItemLabels: PayStatementItemLabel[];
  isPayStatementsLoading: boolean;
  selectedTab: ConnectionDetailTab;
  setSelectedTab: (index: ConnectionDetailTab) => void;
}) => {
  const statusLog = first(connectionDetail?.statusTimeline);
  const assistedProduct = connectionDetail?.products.find((product) =>
    isAssistedImplementationKind(product.implementationKind),
  );
  const [selectedSession, setSelectedSession] = useState<ConnectSession | null>(
    null,
  );
  const [activeSessionEventsTab, setActiveSessionEventsTab] = useState(
    SessionHistoryTab.Details,
  );
  const isApiExplorerEnabled = useFlag(FeatureFlag.DashboardApiExplorer);
  const isPayStatementMappingEnabled =
    useFlag(FeatureFlag.PayStatementMapping) ||
    (connectionConfiguration && connectionConfiguration.enabled);

  const isTabVisible = (key: number) => {
    switch (key) {
      case ConnectionDetailTab.ASSISTED_SETUP_STATUS:
        return connectionDetail.products.some((product) =>
          isAssistedImplementationKind(product.implementationKind),
        );
      case ConnectionDetailTab.API_EXPLORER:
        return isApiExplorerEnabled;
      case ConnectionDetailTab.PAY_STATEMENT_MAPPING:
        return (
          isPayStatementMappingEnabled &&
          connectionDetail &&
          connectionDetail.products
            .flatMap((p) => p.scopes)
            .includes('employer:pay_statement')
        );
      default:
        return true;
    }
  };

  const onTabChange = (index: number) => {
    setSelectedTab(index);
  };

  const visibleTabs = Object.entries(connectionDetailTabs).filter(
    ([key]) =>
      isTabVisible(parseInt(key, 10)) &&
      // Only show the overview tab if the connection is disconnected
      (parseInt(key, 10) === ConnectionDetailTab.OVERVIEW ||
        connectionDetail.connectionStatus !== AccountStatus.DISCONNECTED),
  );

  const navigateToSessionEventsTab = (session: ConnectSession) => {
    setSelectedTab(ConnectionDetailTab.CONNECT_SESSIONS);
    setActiveSessionEventsTab(SessionHistoryTab.Events);
    setSelectedSession(session);
  };

  return (
    <Tabs
      flexGrow={1}
      index={selectedTab}
      onChange={onTabChange}
      // marginTop="-43px" // NOTE: some design things that need to be thought out for long company names
    >
      <AppTabList gap={6}>
        {visibleTabs.map(([key, value]) => (
          <AppTab key={key} isNew={isTabNew(parseInt(key))}>
            {value}
          </AppTab>
        ))}
      </AppTabList>
      <TabPanels>
        {visibleTabs.map(([key]) => (
          <TabPanel key={key} pl="0" pr="0" pb="0">
            {TAB_TO_COMPONENT[parseInt(key, 10) as ConnectionDetailTab]({
              jobs,
              payStatementItem,
              payStatementItemLabels,
              isPayStatementsLoading,
              statusLog,
              assistedProduct,
              connectionDetail,
              navigateToSessionEventsTab,
              selectedSession,
              setSelectedSession,
              activeSessionEventsTab,
              setActiveSessionEventsTab,
            })}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};

export const ConnectionDetailContent = ({
  connectionConfiguration,
  connectionDetail,
  jobs,
  payStatementItem,
  payStatementItemLabels,
  isPayStatementsLoading,
  selectedTab,
  setSelectedTab,
}: {
  connectionConfiguration: ConnectionConfiguration;
  connectionDetail: ConnectionAccountDetail;
  jobs: JobEntry[];
  payStatementItem: PayStatementItem;
  payStatementItemLabels: PayStatementItemLabel[];
  isPayStatementsLoading: boolean;
  selectedTab: ConnectionDetailTab;
  setSelectedTab: (index: ConnectionDetailTab) => void;
}) => {
  return (
    <Flex gap="24px" direction="column" align="stretch">
      <ConnectionDetailTabs
        connectionConfiguration={connectionConfiguration}
        connectionDetail={connectionDetail}
        jobs={jobs}
        payStatementItem={payStatementItem}
        payStatementItemLabels={payStatementItemLabels}
        isPayStatementsLoading={isPayStatementsLoading}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
    </Flex>
  );
};
