import { Flex, Stack, Text } from '@chakra-ui/react';
import { ReactNode, useRef, useState } from 'react';
import { BsChevronDown } from 'react-icons/bs';
import { motion } from 'framer-motion';

type PayStatementCategoryExpandProps = {
  children: ReactNode;
  remainingItemCount: number;
};

export function PayStatementCategoryExpand(
  props: PayStatementCategoryExpandProps,
) {
  const [open, setOpen] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  return (
    <Stack>
      <motion.div
        animate={{
          height: open ? ref.current?.clientHeight : 0,
        }}
        style={{
          overflow: 'hidden',
        }}
        layout
      >
        <Stack width={'100%'} ref={ref}>
          {props.children}
        </Stack>
      </motion.div>
      <Flex
        borderTop={'1px solid #dedede'}
        alignItems={'center'}
        justifyContent={'center'}
        flexDirection={'row'}
        onClick={() => setOpen(!open)}
        cursor={'pointer'}
        paddingY={2}
        gap={2}
      >
        <Text fontSize={'14px'} color={'#666'}>
          {open ? 'Show less' : `Show ${props.remainingItemCount} more`}
        </Text>
        <motion.div animate={{ rotate: open ? 180 : 0 }}>
          <BsChevronDown
            style={{
              stroke: '#000',
              strokeWidth: 0.5,
              fill: '#666',
              height: '10px',
            }}
          />
        </motion.div>
      </Flex>
    </Stack>
  );
}
