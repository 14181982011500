import { Box, Flex, HStack, Stack, Text, Tooltip } from '@chakra-ui/react';

import { ContentBox } from '../../../../components/ContentBox';
import { categoryLabel } from '../constants';
import { Category, Item, Option } from '../types';
import { PayStatementCategoryRow } from './pay-statement-category-row';
import { InfoIcon } from '../../../../shared/InfoIcon';
import { PayStatementCategoryExpand } from './pay-statement-category-expand';

function PayStatementCategoryGroupColumns({ header }: { header: Category }) {
  return (
    <HStack paddingX="24px" paddingY="12px" gap="32px">
      <Box width="100%" maxWidth="580px">
        <Flex gap="8px">
          <Text fontSize="sm" fontWeight={500}>
            Pay Statement Item Name
          </Text>
        </Flex>
      </Box>
      <Box width="100%">
        <Flex gap="8px">
          <Text fontSize="sm" fontWeight={500}>
            Label
          </Text>
        </Flex>
      </Box>
      {header === 'earnings' ? (
        <Flex gap="4px" minWidth="73px">
          <Text fontSize="sm" fontWeight={500}>
            Eligible
          </Text>
          <Tooltip
            placement="top"
            label="Choose whether this Earnings item is eligible in this employer’s plan or not"
            shouldWrapChildren
          >
            <InfoIcon />
          </Tooltip>
        </Flex>
      ) : null}
    </HStack>
  );
}

export function PayStatementCategoryGroup({
  header,
  items,
  labels,
  editing,
  disabled,
  onChange,
  onToggle,
  checked,
  values,
}: {
  editing: boolean;
  header: Category;
  items: Item[];
  labels: Option[];
  values: Partial<Record<Category, Record<string, string | null | undefined>>>;
  checked: Partial<Record<Category, Record<string, boolean>>>;
  onChange: (item: Item, label: string | string[]) => void;
  onToggle: (item: Item, polarity: boolean) => void;
  disabled: boolean;
}) {
  return (
    <Stack gap="12px" marginBottom="16px">
      <Text fontSize="md" fontWeight={500}>
        {categoryLabel[header]}
      </Text>
      <ContentBox>
        <PayStatementCategoryGroupColumns header={header} />
        {items.slice(0, 3).map((item, i) => (
          <PayStatementCategoryRow
            key={i}
            options={labels}
            editing={editing}
            disabled={disabled}
            category={item.category}
            name={item.name}
            checked={checked?.[item.category]?.[item.name]}
            value={values?.[item.category]?.[item.name] ?? undefined}
            onChange={(value) => onChange(item, value)}
            onToggle={
              item.category === 'earnings'
                ? (polarity) => onToggle(item, polarity)
                : undefined
            }
          />
        ))}
        {items.length > 3 ? (
          <PayStatementCategoryExpand
            remainingItemCount={items.slice(3).length}
          >
            {items.slice(3).map((item, i) => (
              <PayStatementCategoryRow
                key={i}
                options={labels}
                editing={editing}
                disabled={disabled}
                category={item.category}
                name={item.name}
                checked={checked?.[item.category]?.[item.name]}
                value={values?.[item.category]?.[item.name] ?? undefined}
                onChange={(value) => onChange(item, value)}
                onToggle={
                  item.category === 'earnings'
                    ? (polarity) => onToggle(item, polarity)
                    : undefined
                }
              />
            ))}
          </PayStatementCategoryExpand>
        ) : (
          ''
        )}
      </ContentBox>
    </Stack>
  );
}
