import {
  Heading,
  ModalBody,
  Image,
  Text,
  Button,
  Stack,
} from '@chakra-ui/react';
import { ConnectSessionRow } from 'connections-v2/hooks/useBulkConnectSessions';
import SuccessCircle from 'assets/images/icons/successCircle.svg';
import { exportCSV, mapDataToExportData } from 'utils/exportCsv';

const CsvToConnectSessionMap = {
  'Customer ID': 'customerId',
  'Customer Name': 'customerName',
  'Customer Email': 'customerEmail',
  'Finch Connect Link': 'connectUrl',
} as const;

export const SessionsDownloadPage = ({
  rows,
}: {
  rows: ConnectSessionRow[];
}) => {
  const onDownload = () => {
    exportCSV({
      data: mapDataToExportData(rows, CsvToConnectSessionMap),
      headers: Object.keys(CsvToConnectSessionMap),
      fileName: `generated-finch-connect-links-${new Date().toISOString()}`,
    });
  };

  return (
    <>
      <ModalBody pt="120px">
        <Stack spacing="24px" align="center">
          <Image src={SuccessCircle} width="64px" height="64px" />

          <Stack spacing="16px">
            <Heading
              fontWeight="medium"
              fontSize="20px"
              lineHeight="26px"
              textAlign="center"
            >
              Your Finch Connect Links are ready
            </Heading>
            <Text textAlign="center">
              This CSV file contains {rows.length} Finch Connect link
              {rows.length > 1 ? 's' : ''} for each employer.
            </Text>
          </Stack>
          <Button
            w="full"
            variant="primary"
            onClick={onDownload}
            h="52px"
            fontSize="16px"
          >
            Download CSV
          </Button>
        </Stack>
      </ModalBody>
    </>
  );
};
