import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useConnectionDetailApi } from '../../../hooks/useConnectionDetailApi';
import { useStatusToast } from '../../../../shared/StatusToast';
import { usePayStatementItemStore } from '../store/pay-statement-store';
import { useParams } from 'react-router-dom';
import { Category, Input, Item } from '../types';

export function useFormControls(initial: Item[]) {
  const { applicationId, connectionId } = useParams<{
    applicationId: string;
    connectionId: string;
  }>();
  const api = useConnectionDetailApi();
  const toast = useStatusToast('bottom');
  const {
    editing,
    setEditing,
    labels,
    eligible,
    clear,
    setEligible,
    showingSince,
    setShowingSince,
    resetShowingSince,
  } = usePayStatementItemStore();
  const queryClient = useQueryClient();
  const save = useMutation({
    mutationFn: async (labels: Input[]) =>
      api.createPayStatementLabels(applicationId, connectionId, labels),
    onSuccess: (data) => {
      setEditing(false);
      toast({
        wasSuccessful: true,
        message: 'Pay Statement Labels have been successfully updated',
      });
      queryClient.invalidateQueries({
        queryKey: [
          'connection-pay-statement-item',
          applicationId,
          connectionId,
          showingSince,
        ],
      });
      clear();
    },
    onError: (err) => {
      toast({
        wasSuccessful: false,
        message: 'Failed to update one or more Pay Statement Labels',
      });
    },
  });

  return {
    loading: save.isPending,
    editing,
    showingSince,
    setShowingSince: (date: Date) => {
      setShowingSince(date);
      queryClient.invalidateQueries({
        queryKey: [
          'connection-pay-statement-item',
          applicationId,
          connectionId,
          showingSince,
        ],
      });
    },
    resetShowingSince,
    onEdit: () => {
      // set defaults for unmapped items
      for (const item of initial) {
        if (item.category !== 'earnings') {
          continue;
        }

        const mapped =
          item.attributes.metadata !== null &&
          '_RETIREMENT_PLAN_ELIGIBILITY' in item.attributes.metadata &&
          typeof item.attributes.metadata._RETIREMENT_PLAN_ELIGIBILITY ===
            'boolean';

        if (!mapped) {
          setEligible(item.category, item.name, true);
        }
      }

      setEditing(true);
    },
    onCancel: () => {
      setEditing(false);
      clear();
    },
    onSave: () => {
      const payload: Input[] = [];

      for (const item of initial) {
        if (!(item.category in labels || item.category in eligible)) {
          continue;
        }

        const assignedLabel = labels[item.category]?.[item.name];
        const pensionProColumn = item.attributes.metadata
          ? item.attributes.metadata._PENSION_PRO_COLUMN
          : undefined;
        const label =
          assignedLabel === null
            ? undefined
            : assignedLabel ?? pensionProColumn;
        let eligibility = eligible[item.category]?.[item.name];

        if (item.attributes.metadata !== null) {
          eligibility =
            eligibility ??
            item.attributes.metadata._RETIREMENT_PLAN_ELIGIBILITY;

          if (
            label === item.attributes.metadata._PENSION_PRO_COLUMN &&
            eligibility ===
              item.attributes.metadata._RETIREMENT_PLAN_ELIGIBILITY
          ) {
            continue;
          }
        }

        if (eligibility === undefined && !label) {
          continue;
        }

        payload.push({
          category: item.category,
          name: item.name,
          label: assignedLabel ?? undefined,
          eligible: eligibility,
        });
      }

      save.mutate(payload);
    },
  };
}
