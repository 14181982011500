import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalProps as ChakraModalProps,
} from '@chakra-ui/react';

type ModalProps = Omit<ChakraModalProps, 'children'> & {
  header?: React.ReactNode;
  body?: React.ReactNode;
  footer?: React.ReactNode;
};

export const Modal = ({ header, body, footer, ...props }: ModalProps) => {
  return (
    <ChakraModal isCentered {...props}>
      <ModalOverlay />
      <ModalContent textAlign="center" alignItems="center" paddingX="6">
        <ModalHeader fontWeight="500" textAlign="center">
          {header}
        </ModalHeader>
        <ModalBody paddingY="0" paddingX="6">
          {body}
        </ModalBody>
        <ModalFooter paddingTop="9" paddingBottom="6">
          {footer}
        </ModalFooter>
      </ModalContent>
    </ChakraModal>
  );
};
