import { ReactNode } from 'react';
import { ContentBox } from '../../../../components/ContentBox';
import { Flex, Stack, Text } from '@chakra-ui/react';
import { Caption } from '../../../../shared/Typography';
import { RxListBullet } from 'react-icons/rx';
import { RiCheckboxBlankLine, RiCheckboxFill } from 'react-icons/ri';
import {
  AiFillDollarCircle,
  AiOutlineDollarCircle,
  AiTwotoneBank,
} from 'react-icons/ai';
import { Category, Item } from '../types';
import flatten from 'lodash/flatten';
import { FilterType } from '../pages';

const CATEGORY_ICON_MAP: (isSelected: boolean) => Record<string, ReactNode> = (
  isSelected: boolean,
) => ({
  earnings: (
    <AiFillDollarCircle
      style={{
        fill: isSelected ? '#3E4AE7' : '#737373',
      }}
    />
  ),
  employee_deductions: (
    <AiOutlineDollarCircle
      style={{
        fill: isSelected ? '#3E4AE7' : '#737373',
      }}
    />
  ),
  employer_contributions: (
    <AiTwotoneBank
      style={{
        fill: isSelected ? '#3E4AE7' : '#737373',
      }}
    />
  ),
});

const CATEGORY_FILTERS = () => [
  {
    label: 'Earnings',
    value: 'earnings',
  },
  {
    label: 'Deductions',
    value: 'employee_deductions',
  },
  {
    label: 'Contributions',
    value: 'employer_contributions',
  },
];
const FILTERS = (selectedFilter?: string) => [
  {
    label: 'All Items',
    value: 'all_items',
    icon: (
      <RxListBullet
        style={{
          width: '15px',
          height: '15px',
          strokeWidth: '0.5px',
          fill: selectedFilter === 'all_items' ? '#3E4AE7' : '#737373',
        }}
      />
    ),
    countFunction: (categories: Map<Category, Item[]>) => {
      return Object.values(Object.fromEntries(categories)).reduce(
        (acc, curr) => acc + curr.length,
        0,
      );
    },
  },
  {
    label: 'All Mapped',
    value: 'all_mapped',
    icon: (
      <RiCheckboxFill
        style={{
          fill: selectedFilter === 'all_mapped' ? '#3E4AE7' : '#737373',
        }}
      />
    ),
    countFunction: (categories: Map<Category, Item[]>) => {
      // toDo: Determine how we are going to leverage required count here
      const allItems = (
        flatten(Object.values(Object.fromEntries(categories))) as Item[]
      ).filter(
        (item) =>
          item.attributes.metadata &&
          item.attributes.metadata._PENSION_PRO_COLUMN,
      );

      return allItems.length;
    },
  },
  {
    label: 'All Unmapped',
    value: 'all_unmapped',
    icon: (
      <RiCheckboxBlankLine
        style={{
          fill: selectedFilter === 'all_unmapped' ? '#3E4AE7' : '#737373',
        }}
      />
    ),
    countFunction: (categories: Map<Category, Item[]>) => {
      // toDo: Determine how we are going to leverage required count here
      const allItems = (
        flatten(Object.values(Object.fromEntries(categories))) as Item[]
      ).filter(
        (item) =>
          !item.attributes.metadata ||
          !item.attributes.metadata._PENSION_PRO_COLUMN,
      );

      return allItems.length;
    },
  },
];

export type PayStatementFilterRowProps = {
  icon: React.ReactNode;
  label: string;
  value: string;
  selected: boolean;
  count: number;
  onClick: () => void;
};

export function PayStatementFilterRow({
  icon,
  label,
  selected,
  count,
  onClick,
}: PayStatementFilterRowProps) {
  return (
    <Flex
      direction={'row'}
      justify={'space-between'}
      grow={1}
      cursor={'pointer'}
      onClick={onClick}
      paddingX={2}
      borderRadius={'8px'}
      paddingY={1}
      backgroundColor={'transparent'}
    >
      <Flex direction={'row'} grow={1} justify={'space-between'}>
        <Flex direction={'row'} gap={4} align={'center'}>
          {icon}
          <Text
            fontWeight={'medium'}
            color={selected ? '#3E4AE7' : '#737373'}
            whiteSpace={'nowrap'}
          >
            {label}
          </Text>
        </Flex>
        <Flex>
          <Text fontWeight={'medium'} color={selected ? '#3E4AE7' : '#737373'}>
            {count}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
}

export function PayStatementCategorySelector({
  categories,
  selectedCategories,
  setSelectedCategories,
  selectedFilter,
  setSelectedFilter,
}: {
  selectedCategories: Category[];
  categories: Map<Category, Item[]>;
  setSelectedCategories: (categories: Category[]) => void;
  selectedFilter?: string;
  setSelectedFilter: (filter: FilterType) => void;
}) {
  return (
    <ContentBox padding={4} minWidth={'300px'} height={'fit-content'}>
      <Stack gap={4}>
        <Stack gap={2}>
          {FILTERS(selectedFilter).map((filter) => (
            <PayStatementFilterRow
              key={filter.value}
              {...filter}
              selected={selectedFilter === filter.value}
              count={filter.countFunction(categories)}
              onClick={() => setSelectedFilter(filter.value as FilterType)}
            />
          ))}
        </Stack>
        <Stack gap={2}>
          <Caption color={'#737373'}>Categories</Caption>
          {CATEGORY_FILTERS().map((category) => (
            <PayStatementFilterRow
              key={category.value}
              {...category}
              icon={
                CATEGORY_ICON_MAP(
                  selectedCategories.includes(category.value as Category),
                )[category.value]
              }
              selected={selectedCategories.includes(category.value as Category)}
              count={categories.get(category.value as Category)?.length ?? 0}
              onClick={() => {
                if (selectedCategories.includes(category.value as Category)) {
                  setSelectedCategories(
                    selectedCategories.filter((c) => c !== category.value),
                  );
                } else {
                  setSelectedCategories([
                    ...selectedCategories,
                    category.value,
                  ] as Category[]);
                }
              }}
            />
          ))}
        </Stack>
      </Stack>
    </ContentBox>
  );
}
