import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  useDisclosure,
  Text,
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuOptionGroup,
  Input,
  Stack,
  ModalHeader,
  ModalBody,
  ModalOverlay,
  Modal,
  ModalContent,
  ModalFooter,
  HStack,
} from '@chakra-ui/react';
import noop from 'lodash/noop';
import moment from 'moment';
import { PillButton } from '../../../../components/PillButton';
import { EditIcon } from '../../../../shared/icons/EditIcon';
import { DownloadIcon } from '../../../../shared/icons/Download';
import { useLabels } from '../hooks/use-labels';
import { useEligibility } from '../hooks/use-eligibility';
import { PayStatementEditConfirmationModal } from './pay-statement-edit-confirmation-modal';
import { COLORS } from '../../../../constant';
import { ChevronDownIcon } from '../../../../shared/icons/ChevronDownIcon';
import { CalendarContainer } from '../../../../shared/DatePickerGlobalStyles';
import ReactDatePicker from 'react-datepicker';
import {
  ActionMenu,
  SecondaryCondensedActionMenu,
} from '../../../../components/ActionMenu';
import { CalendarIcon } from '../../../../shared/icons/Calendar';
import { PayStatementInputCalendar } from './pay-statement-input-calendar';

function PayStatementItemDate({
  selected,
  setDate,
}: {
  selected: Date;
  setDate: (date: Date) => void;
}) {
  return (
    <>
      <Text
        fontSize="13px"
        backgroundColor={COLORS.GRAY.GRAY_300}
        paddingY="2px"
        paddingX="8px"
        borderRadius="6px"
      >
        Showing items since:
      </Text>
      <Menu variant="secondary">
        <MenuButton
          as={Button}
          borderRadius="lg"
          border="1px solid"
          borderColor={COLORS.GRAY.GRAY_400}
          background={COLORS.WHITE}
          padding="8px"
          rightIcon={<ChevronDownIcon />}
          _active={{ background: 'none', borderColor: COLORS.FINCH.PURPLE }}
          _hover={{ textDecoration: 'unset' }}
          color={COLORS.FINCH.BLACK}
          height="6"
          fontSize="13px"
          textAlign="left"
        >
          {1 + selected.getMonth()}/{selected.getDate()}/
          {selected.getFullYear()}
        </MenuButton>
        <MenuList padding="16px">
          <MenuOptionGroup>
            <Stack gap="8px">
              <Text fontSize="14px">New date</Text>
              <ReactDatePicker
                placeholderText="MM/DD/YYYY"
                dateFormat="MM/dd/yyyy"
                selected={selected}
                maxDate={new Date()}
                onChange={(date) => {
                  setDate(date ?? selected);
                }}
                customInput={<Input fontSize="14px" />}
                calendarContainer={CalendarContainer}
              />
            </Stack>
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    </>
  );
}

export function PayStatementCategoryEditControls({
  remapping,
  disabled,
  editing,
  setEditing,
  saveEdits,
  cancelEdits,
  download,
  setShowingSince,
  showingSince,
}: {
  remapping: boolean;
  disabled: boolean;
  editing: boolean;
  showingSince: Date;
  setShowingSince: (date: Date) => void;
  setEditing: () => void;
  saveEdits: () => void;
  cancelEdits: () => void;
  download: () => void;
}) {
  const {
    isOpen: isConfirmationModalOpen,
    onOpen: onConfirmationModalOpen,
    onClose: onConfirmationModalClose,
  } = useDisclosure();
  const { labels } = useLabels();
  const { eligible } = useEligibility();

  const initialDate = useRef<Date | null>(null);

  useEffect(() => {
    initialDate.current = showingSince;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveable = useMemo(
    () =>
      !disabled &&
      (Object.entries(labels).length > 0 ||
        Object.entries(eligible).length > 0),
    [labels, eligible, disabled],
  );

  const confirm = useCallback(() => {
    onConfirmationModalClose();

    return saveEdits();
  }, [onConfirmationModalClose, saveEdits]);

  const onSave = useCallback(() => {
    if (remapping) {
      return onConfirmationModalOpen();
    }

    return confirm();
  }, [remapping, confirm, onConfirmationModalOpen]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [localCurrentDate, setLocalCurrentDate] = useState(
    moment(showingSince).format('MM/DD/YYYY'),
  );

  useEffect(() => {
    if (
      localCurrentDate.length === 10 &&
      moment(localCurrentDate, 'MM/DD/YYYY').isValid()
    ) {
      setShowingSince(moment(localCurrentDate, 'MM/DD/YYYY').toDate());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localCurrentDate]);

  if (editing) {
    return (
      <>
        <PillButton
          variant="secondary"
          onClick={disabled ? noop : cancelEdits}
          disabled={disabled}
        >
          Cancel
        </PillButton>
        <PillButton
          variant="primaryPurple"
          type="submit"
          onClick={saveable ? onSave : noop}
          isDisabled={!saveable}
          disabled={!saveable}
        >
          Save Changes
        </PillButton>

        {isConfirmationModalOpen && (
          <PayStatementEditConfirmationModal
            isOpen={isConfirmationModalOpen}
            onCancel={onConfirmationModalClose}
            onClose={onConfirmationModalClose}
            onSave={disabled ? noop : confirm}
          />
        )}
      </>
    );
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            fontSize="18px"
            fontWeight="semibold"
            color={COLORS.FINCH.BLACK}
            alignSelf="center"
            style={{
              paddingBottom: '0px',
            }}
          >
            Change Pay Statement Date
          </ModalHeader>
          <ModalBody>
            <Stack>
              <Text fontSize="14px" color={COLORS.FINCH.GRAY} align={'center'}>
                All pay statements items are dated to January 1, 2024.
              </Text>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '4px',
                  padding: '10px 0',
                }}
              >
                <Text fontSize="14px" color={COLORS.FINCH.GRAY}>
                  Previous Date
                </Text>
                <Input
                  disabled
                  value={moment(initialDate.current).format('MM/DD/YYYY')}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '4px',
                  padding: '5px 0px 10px 0px',
                }}
              >
                <Text fontSize="14px" color={COLORS.FINCH.GRAY}>
                  Current Date
                </Text>
                <Input
                  onChange={(e) => {
                    setLocalCurrentDate(e.target.value);
                  }}
                  value={localCurrentDate}
                  style={{
                    borderBottomLeftRadius: '0px',
                    borderBottomRightRadius: '0px',
                  }}
                  _focus={{
                    border: '1px solid',
                    outline: 'none',
                    borderColor: COLORS.FINCH.PURPLE,
                  }}
                />
                <PayStatementInputCalendar
                  value={moment(showingSince).format('MM/DD/YYYY')}
                  onChange={(e) => {
                    setShowingSince(e);
                    setLocalCurrentDate(moment(e).format('MM/DD/YYYY'));
                  }}
                />
              </div>
            </Stack>
          </ModalBody>
          <ModalFooter w={'100%'}>
            <HStack w={'100%'} gap={4}>
              <Button onClick={onClose} variant="outline" w={'100%'}>
                Cancel
              </Button>
              <Button
                onClick={() => {
                  onSave();
                  onClose();
                }}
                backgroundColor={COLORS.FINCH.PURPLE}
                _hover={{
                  backgroundColor: '#333dbd',
                }}
                color={COLORS.WHITE}
                alignItems="center"
                w={'100%'}
                isDisabled={showingSince === initialDate.current}
              >
                Yes, save changes
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {editing ? (
        <>
          <PillButton
            variant="secondary"
            onClick={disabled ? noop : cancelEdits}
            disabled={disabled}
          >
            Cancel
          </PillButton>
          <PillButton
            variant="primaryPurple"
            type="submit"
            onClick={saveable ? onSave : noop}
            isDisabled={!saveable}
            disabled={!saveable}
          >
            Save Changes
          </PillButton>
          isConfirmationModalOpen && (
          <PayStatementEditConfirmationModal
            isOpen={isConfirmationModalOpen}
            onCancel={onConfirmationModalClose}
            onClose={onConfirmationModalClose}
            onSave={disabled ? noop : confirm}
          />
          )
        </>
      ) : (
        <SecondaryCondensedActionMenu
          menuItems={[
            {
              icon: <EditIcon hollow />,
              label: 'Edit mappings',
              onClick: disabled ? noop : setEditing,
            },
            {
              icon: <CalendarIcon />,
              label: 'Change pay statement date...',
              onClick: () => {
                onOpen();
              },
            },
            {
              icon: <DownloadIcon />,
              label: 'Download CSV',
              onClick: disabled ? noop : download,
            },
          ]}
        />
      )}
    </>
  );
}
