import { useQuery } from '@tanstack/react-query';
import { useConnectionDetailApi } from './useConnectionDetailApi';

export function useConnectionConfiguration({
  applicationId,
  connectionId,
  thirdParty,
}: {
  applicationId: string;
  connectionId: string;
  thirdParty: 'pensionPro';
}) {
  const api = useConnectionDetailApi();
  const { isLoading, data } = useQuery({
    initialData: null,
    queryKey: [
      'connection-detail-configuration',
      applicationId,
      connectionId,
      thirdParty,
    ],
    queryFn: () =>
      api.getConfiguration(applicationId, connectionId, thirdParty),
  });

  return { connectionConfiguration: data, isLoading };
}
