import { COLORS } from 'constant';
import { ChevronDownIcon } from 'shared/icons/ChevronDownIcon';
import {
  Button,
  Flex,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { ReactNode } from 'react';

const ActionMenuItem = ({
  icon,
  text,
  color = COLORS.GRAY.GRAY_600,
  bgHoverColor = COLORS.GRAY.GRAY_200,
  textHoverColor = COLORS.FINCH.BLACK,
  onClick,
}: {
  icon: ReactNode;
  text: string;
  color?: string;
  bgHoverColor?: string;
  textHoverColor?: string;
  onClick?: () => void;
}) => {
  const hoverOptions = {
    backgroundColor: bgHoverColor,
    borderRadius: '4px',
    color: textHoverColor,
  };

  return (
    <MenuItem
      _focus={hoverOptions}
      _hover={hoverOptions}
      color={color}
      fontSize="sm"
      onClick={onClick}
    >
      <Flex gap={1}>
        <HStack spacing="8px">
          <Icon viewBox="0 0 20 20" fontSize="20px">
            {icon}
          </Icon>
          <Text fontSize="sm">{text}</Text>
        </HStack>
      </Flex>
    </MenuItem>
  );
};

const MenuListComponent = ({
  menuItems,
}: {
  menuItems: {
    icon: ReactNode;
    label: string;
    onClick: () => void;
    color?: string;
    bgHoverColor?: string;
    textHoverColor?: string;
  }[];
}) => {
  return (
    <MenuList minWidth="200px" borderRadius={'8px'}>
      {menuItems.map(
        ({ icon, label, onClick, color, bgHoverColor, textHoverColor }) => (
          <ActionMenuItem
            key={label}
            icon={icon}
            bgHoverColor={bgHoverColor}
            color={color}
            text={label}
            onClick={onClick}
            textHoverColor={textHoverColor}
          />
        ),
      )}
    </MenuList>
  );
};

export const ActionMenu = ({
  menuItems,
  isLoading,
  isDisabled,
}: {
  isLoading?: boolean;
  isDisabled?: boolean;
  menuItems: {
    icon: ReactNode;
    label: string;
    onClick: () => void;
    color?: string;
    bgHoverColor?: string;
    textHoverColor?: string;
  }[];
}) => {
  return (
    <Menu placement="bottom-end">
      <MenuButton
        as={Button}
        aria-label="Actions"
        rightIcon={<Icon as={ChevronDownIcon} color="white"></Icon>}
        size="md"
        fontSize="sm"
        variant="primaryPurple"
        isLoading={isLoading}
        isDisabled={isDisabled}
      >
        Actions
      </MenuButton>
      <MenuListComponent menuItems={menuItems} />
    </Menu>
  );
};

export const SecondaryCondensedActionMenu = ({
  menuItems,
  isLoading,
  isDisabled,
}: {
  isLoading?: boolean;
  isDisabled?: boolean;
  menuItems: {
    icon: ReactNode;
    label: string;
    onClick: () => void;
    color?: string;
    bgHoverColor?: string;
    textHoverColor?: string;
  }[];
}) => {
  return (
    <Menu placement="bottom-end">
      <MenuButton
        as={Button}
        aria-label="Actions"
        rightIcon={<Icon as={ChevronDownIcon} color="white"></Icon>}
        borderRadius="lg"
        border="1px solid"
        borderColor={COLORS.GRAY.GRAY_400}
        background={COLORS.WHITE}
        padding="8px"
        _active={{ background: 'none', borderColor: COLORS.FINCH.PURPLE }}
        _hover={{ textDecoration: 'unset' }}
        color={COLORS.FINCH.BLACK}
        height="6"
        fontSize="13px"
        textAlign="left"
        isLoading={isLoading}
        isDisabled={isDisabled}
      >
        Actions
      </MenuButton>
      <MenuListComponent menuItems={menuItems} />
    </Menu>
  );
};
