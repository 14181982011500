import { HStack, Stack, Text } from '@chakra-ui/react';

import { ProviderEntryProps } from '../types';
import { ProviderIcon } from '../../components/Iconagraphy/ProviderIcon';
import { ContentBox } from '../../components/ContentBox';
import { memo } from 'react';
import { ProviderSwitch } from './ProviderSwitch';
import { EditButton } from './EditButton';
import { PinProvider } from './PinProvider';
import { ConfigurationNeededToolTip } from './ConfigurationNeededToolTip';
import { getConfigurationNeededText, isProviderEnabled } from '../utils';
import { UnsavedConfigIndicator } from '../../shared/icons/UnsavedConfigIndicator';
import { PERMISSION } from '../../constant/roles-and-permissions';
import { usePermissions } from '../../hooks/use-permissions';
import { useApplication } from 'applications/hooks';

export const ProviderEntry = memo(function ProviderEntry({
  provider,
  providerSettings,
  updateIsPinned,
  updateEnabled,
  isLastItem,
  setSelectedProviderId,
  hasStagedConfiguration,
  canUpdate,
}: ProviderEntryProps) {
  const { application } = useApplication();
  const providerEnabled = isProviderEnabled(provider, canUpdate);
  const configurationNeededText = getConfigurationNeededText({
    providerSettings,
    providerWithImplementation: provider,
    isSandbox: application?.isSandbox() ?? false,
  });

  const roleCanUpdateConfigurations = usePermissions([
    PERMISSION.IntegrationConfigurations.Update,
  ]);

  return (
    <ContentBox
      key={provider.id}
      as="li"
      aria-label={provider.displayName}
      sx={{ listStyle: 'none' }}
      borderRadius="0"
      p="16px 22px"
      mb={isLastItem ? '120px' : '0'}
      borderBottomRadius={isLastItem ? '8px' : '0'}
    >
      <Stack direction={{ base: 'column', lg: 'row' }} justify="space-between">
        <HStack opacity={providerEnabled ? 1 : 0.3}>
          <PinProvider
            provider={provider}
            updateIsPinned={updateIsPinned}
            enabled={providerEnabled && roleCanUpdateConfigurations}
            disableTooltip={!providerEnabled}
          />

          <ProviderIcon
            size="lg"
            src={provider.icon}
            provider={provider.displayName}
          />
          <Text>{provider.displayName}</Text>
          {hasStagedConfiguration(provider.id) && <UnsavedConfigIndicator />}
        </HStack>
        <HStack justify="flex-end" spacing="16px">
          {configurationNeededText && (
            <ConfigurationNeededToolTip
              text={configurationNeededText.tooltip}
            />
          )}
          <ProviderSwitch
            id={provider.id}
            checked={providerEnabled}
            onChange={() => updateEnabled(provider.id, !provider.enabled)}
            disabled={!roleCanUpdateConfigurations || !canUpdate}
          />

          {roleCanUpdateConfigurations && (
            <EditButton onClick={() => setSelectedProviderId(provider.id)} />
          )}
        </HStack>
      </Stack>
    </ContentBox>
  );
});
