export const FileDownloadIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.25 15H10.75V11.875L11.9375 13.0625L13 12L10 9L7 12L8.0625 13.0625L9.25 11.875V15ZM4 18V2H12L16 6V18H4ZM11 7H14.5L11 3.5V7Z"
      fill="#027A48"
    />
    <path d="M11 7H16V6L12 2H11V7Z" fill="#005733" />
    <rect x="5.5" y="8" width="9" height="8" fill="#027A48" />
    <path
      d="M9.25 9L10.75 9L10.75 12.125L11.9375 10.9375L13 12L10 15L7 12L8.0625 10.9375L9.25 12.125L9.25 9Z"
      fill="white"
    />
  </svg>
);
