import {
  ImplementationKind,
  isAssistedImplementationKind,
} from '@finch-api/common/dist/internal/connect/authorize';
import {
  ImplementationInfoWithAssitedBenefits,
  ProviderSettings,
  ProviderWithImplementation,
} from './types';
import { FINCH_DOCS_BASE_URL } from '../shared/links';
import { ExternalLink } from '../shared/ExternalLink';

/**
 * This list of integrations are known to have flaky implementations that may fail to sync
 * data or authentication.
 *
 * TODO: Move to the `payroll_provider_adapter_implementation` table instead
 */
const FLAKY_INTEGRATIONS: string[] = [];

export const mergeServerAndStagedConfigurations = ({
  serverConfigurations,
  stagedConfigurations,
}: {
  serverConfigurations: ProviderWithImplementation[];
  stagedConfigurations: Map<string, Partial<ProviderWithImplementation>>;
}) => {
  const mergedConfigurations = new Map<string, ProviderWithImplementation>();

  serverConfigurations.forEach((serverConfiguration) => {
    const providerId = serverConfiguration.id;
    const stagedConfiguration = stagedConfigurations.get(providerId);

    if (stagedConfiguration) {
      mergedConfigurations.set(providerId, {
        ...serverConfiguration,
        ...stagedConfiguration,
      });
    } else {
      mergedConfigurations.set(providerId, serverConfiguration);
    }
  });

  const sortedConfigurations = Array.from(mergedConfigurations.values()).sort(
    (a, b) => a.displayName.localeCompare(b.displayName),
  );

  return sortedConfigurations;
};

export const getNewPinnedIndex = (
  configurations: ProviderWithImplementation[],
) => {
  const pinnedConfigurations = configurations.filter(
    (configuration) => configuration.pinnedIndex !== null,
  );

  const pinnedIndexes = pinnedConfigurations.map(
    (configuration) => configuration.pinnedIndex || 0,
  );

  const maxPinnedIndex = pinnedIndexes.length ? Math.max(...pinnedIndexes) : 0;

  return maxPinnedIndex + 1;
};

export const sortByPinnedIndex = (
  a: ProviderWithImplementation,
  b: ProviderWithImplementation,
) => {
  if (a.pinnedIndex === null && b.pinnedIndex === null) {
    return 0;
  }

  if (a.pinnedIndex === null) {
    return 1;
  }

  if (b.pinnedIndex === null) {
    return -1;
  }

  return a.pinnedIndex - b.pinnedIndex;
};

export const orderList = (list: string[], isAlphabetical: boolean) => {
  const newList = [...list];

  if (isAlphabetical) {
    newList.sort((a, b) => a.localeCompare(b));
  }

  return newList;
};

/**
 * Filter out disabled providers, add sort providers by pinnedIndex
 */
export const formatProvidersForConnect = (
  providers: ProviderWithImplementation[],
  pinnedProviders: ProviderWithImplementation[],
) =>
  providers
    .map((provider) => {
      const pinnedIndex = pinnedProviders.findIndex(
        (pinnedProvider) => pinnedProvider.id === provider.id,
      );
      // Filter out disabled implementations
      const implementations = provider.implementations.filter(
        (impl) => impl.enabled,
      );

      return {
        ...provider,
        enabled: provider.enabled && implementations.length > 0,
        implementations,
        pinnedIndex: pinnedIndex === -1 ? null : pinnedIndex,
      };
    })
    .filter((provider) => provider.enabled)
    .sort(sortByPinnedIndex);

export const getImplementations = (
  providerWithImplementation: ProviderWithImplementation,
): ImplementationInfoWithAssitedBenefits[] => {
  const assitedImplementations =
    providerWithImplementation?.implementations.filter(
      (implementation) => implementation.kind === ImplementationKind.ASSISTED,
    );

  const automatedImplementations =
    providerWithImplementation.implementations.filter(
      (implementation) => implementation.kind !== ImplementationKind.ASSISTED,
    );
  // If the provider has at least one automated implementation, we want to show the automated implementations only
  if (automatedImplementations.length > 0) {
    return automatedImplementations.map((implementation) => ({
      ...implementation,
      hasAssistedBenefits: assitedImplementations.some((impl) =>
        impl.scopes.includes('employer:benefits'),
      ),
    }));
  }

  return assitedImplementations;
};

export const isProviderEnabled = (
  providerWithImplementation: ProviderWithImplementation,
  canUpdateProvider: boolean,
) => {
  const implementations = getImplementations(providerWithImplementation);

  return (
    providerWithImplementation.enabled &&
    implementations.some((impl) => impl.enabled) &&
    canUpdateProvider
  );
};

export const areAllSupportedImplementationsDisabled = (
  providerSettings: ProviderSettings,
  providerWithImplementation: ProviderWithImplementation,
) => {
  const implementations = getImplementations(providerWithImplementation);

  return (
    providerSettings.allowedImplementationKinds.every(
      (kind) =>
        implementations.find((impl) => impl.kind === kind) === undefined,
    ) && implementations.length > 0
  );
};

export const isOAuthConfigurationNeeded = (
  providerWithImplementation: ProviderWithImplementation,
) => {
  return (
    providerWithImplementation.requireOAuthCredentialsInput &&
    providerWithImplementation.oauthCredentials === null
  );
};

export const doesProviderRequireAcf = (
  provider: ProviderWithImplementation,
  providerSettings: ProviderSettings,
): boolean => {
  // We only want to check non-assisted implementations for ACF fallback
  const fallbackRequiredImplementations = provider.implementations.filter(
    (impl) =>
      impl.requiresAcfFallback && !isAssistedImplementationKind(impl.kind),
  );

  return (
    fallbackRequiredImplementations.some((impl) => !impl.authFallbackEnabled) &&
    !!providerSettings.authFallbackEligible
  );
};

export const getConfigurationNeededText = (opts: {
  providerSettings: ProviderSettings;
  providerWithImplementation: ProviderWithImplementation;
  isSandbox: boolean;
}): { tooltip: React.ReactNode; banner: React.ReactNode } | null => {
  const { providerSettings, providerWithImplementation, isSandbox } = opts;

  const implementationsDisabledInSettings =
    areAllSupportedImplementationsDisabled(
      providerSettings,
      providerWithImplementation,
    );

  const requireOauthConfig = isSandbox
    ? false
    : isOAuthConfigurationNeeded(providerWithImplementation);

  const acfRequired = doesProviderRequireAcf(
    providerWithImplementation,
    providerSettings,
  );

  const flakyImplementation = FLAKY_INTEGRATIONS.includes(
    providerWithImplementation.id,
  );

  if (acfRequired) {
    const url = `${FINCH_DOCS_BASE_URL}/implementation-guide/Optimize/Increase-Employer-Adoption#set-up-authentication-fallback`;
    return {
      tooltip: `${providerWithImplementation.displayName} requires configuration before it can be enabled`,
      banner: (
        <>
          {providerWithImplementation.displayName} requires Authentication
          Fallback to be enabled. To enable{' '}
          <ExternalLink to={url} textDecoration="underline" color="inherit">
            Authentication Fallback
          </ExternalLink>
          , please reach out to your Finch representative.
        </>
      ),
    };
  }

  if (flakyImplementation) {
    const message = (
      <>
        Your dashboard may show intermittent authentication or data sync
        failures for your {providerWithImplementation.displayName} connections.
        You can expect data to sync within 3 days or less. We encourage you to
        use our{' '}
        <ExternalLink
          to="https://developer.tryfinch.com/developer-resources/Webhooks"
          textDecoration="underline"
          color="inherit"
        >
          webhooks
        </ExternalLink>{' '}
        to get notified when new data is available. While this is expected
        behavior at the moment, we&apos;re working with{' '}
        {providerWithImplementation.displayName} to enhance the integration
        experience over time.
      </>
    );
    return {
      tooltip: message,
      banner: message,
    };
  }

  if (implementationsDisabledInSettings) {
    const message = `All the Authentication Types supported by ${providerWithImplementation.displayName} are currently set to “Off”. To turn them back on, go to Settings > Available Authentication Methods`;
    return {
      tooltip: message,
      banner: message,
    };
  }

  if (requireOauthConfig) {
    const message = `${providerWithImplementation.displayName} requires configuration before the OAuth authentication type can be enabled`;
    return {
      tooltip: message,
      banner: message,
    };
  }

  return null;
};
