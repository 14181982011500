import type { ColumnFiltersState } from '@tanstack/react-table';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import {
  DateRange,
  getTimeValueForSelectedDateRange,
} from '../../components/TableFilters';

type FilterDataMap = {
  connectionId?: {
    id: string;
    value: string;
  };

  companyId?: {
    id: string;
    value: string;
  };

  companyName?: {
    id: string;
    value: string;
  };

  kinds?: {
    id: string;
    value: string[];
  };

  status?: {
    id: string;
    value: string[];
  };

  providerName?: {
    id: string;
    value: string[];
  };

  connectedAt?: {
    id: string;
    value: {
      id: string;
      range: number[];
    };
  };

  latestStepInfo?: {
    id: string;
    value: string[];
  };
};

export const getConnectionFilterFromQueryParams = () => {
  const searchParamsOnLoad = new URLSearchParams(window.location.search);
  const columnFilters = [];

  const companyName = searchParamsOnLoad.get('companyName');

  if (companyName) {
    columnFilters.push({
      id: 'companyName',
      value: companyName,
    });
  }

  const connectionId = searchParamsOnLoad.get('connectionId');

  if (connectionId) {
    columnFilters.push({
      id: 'connectionId',
      value: connectionId,
    });
  }

  const companyId = searchParamsOnLoad.get('companyId');

  if (companyId) {
    columnFilters.push({
      id: 'companyId',
      value: companyId,
    });
  }

  const connectionType = searchParamsOnLoad.get('connectionType');

  if (connectionType) {
    columnFilters.push({
      id: 'kinds',
      value: connectionType.split(','),
    });
  }

  const connectionStatus = searchParamsOnLoad.get('connectionStatus');

  if (connectionStatus) {
    columnFilters.push({
      id: 'status',
      value: connectionStatus.split(','),
    });
  }

  const provider = searchParamsOnLoad.get('provider');

  if (provider) {
    columnFilters.push({
      id: 'providerName',
      value: provider.split(','),
    });
  }

  const dateFilter = searchParamsOnLoad.get('dateFilter') as DateRange;

  if (dateFilter) {
    const dateStart = searchParamsOnLoad.get('dateStart');
    const dateEnd = searchParamsOnLoad.get('dateEnd');

    const isCustomRange = dateFilter === 'custom_range' && dateStart && dateEnd;

    const dateRange: [number, number] = isCustomRange
      ? [Number(dateStart), Number(dateEnd)]
      : getTimeValueForSelectedDateRange(dateFilter);

    columnFilters.push({
      id: 'connectedAt',
      value: {
        id: dateFilter,
        range: dateRange,
      },
    });
  }

  const stageCompletedFilter = searchParamsOnLoad.get('latestStepInfo');

  if (stageCompletedFilter) {
    columnFilters.push({
      id: 'latestStepInfo',
      value: stageCompletedFilter.split(','),
    });
  }

  return columnFilters;
};

export const useConnectionsQuerySync = ({
  columnFilters,
}: {
  columnFilters: ColumnFiltersState;
}) => {
  const history = useHistory();

  // Sync the URL query params with the active filters
  useEffect(() => {
    const currentSearch = window.location.search;
    const newUrlParams = new URLSearchParams(currentSearch);

    const filterMap: FilterDataMap = columnFilters.reduce((acc, filter) => {
      return {
        ...acc,
        [filter.id]: filter,
      };
    }, {});

    newUrlParams.set('companyName', filterMap.companyName?.value || '');
    newUrlParams.set('connectionId', filterMap.connectionId?.value || '');
    newUrlParams.set('companyId', filterMap.companyId?.value || '');
    newUrlParams.set('connectionType', filterMap.kinds?.value?.join(',') || '');
    newUrlParams.set(
      'connectionStatus',
      filterMap.status?.value?.join(',') || '',
    );
    newUrlParams.set(
      'provider',
      filterMap.providerName?.value?.join(',') || '',
    );

    const connectionDateData = filterMap.connectedAt?.value;
    const dateRange = connectionDateData?.range || [];

    newUrlParams.set('dateFilter', connectionDateData?.id || '');
    newUrlParams.set('dateStart', String(dateRange[0] || ''));
    newUrlParams.set('dateEnd', String(dateRange[1] || ''));

    newUrlParams.set(
      'latestStepInfo',
      filterMap.latestStepInfo?.value?.join(',') || '',
    );

    history.replace(`?${newUrlParams}`);
  }, [columnFilters, history]);
};
