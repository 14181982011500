import get from 'lodash/get';
import { RequestBodyWithRef, JsonSchemaSelections } from './types';
import { PRIMITIVE_SCHEMA_TYPE } from './constants';
import type { OpenAPIV3 } from 'openapi-types';

const resolveRef = (ref: string, spec?: OpenAPIV3.Document) => {
  const [, ...pathToSchema] = ref.split('/');
  return get(spec, pathToSchema, {});
};

const nullableArray = ({
  nullable,
  description,
}: {
  nullable: boolean;
  description: string | null;
}) => (nullable ? [{ type: 'null' as const, description }] : []);

const recursivelyConstructTypedRequestObject = (
  input: any,
  spec?: OpenAPIV3.Document,
  required?: boolean,
): JsonSchemaSelections => {
  if (input.$ref) {
    return recursivelyConstructTypedRequestObject(
      resolveRef(input.$ref, spec),
      spec,
    );
  }

  if (input.oneOf?.length) {
    return input.oneOf.map((oneOf: OpenAPIV3.SchemaObject) => {
      const [schema] = recursivelyConstructTypedRequestObject(oneOf, spec);

      return {
        ...schema,
        title: oneOf.title,
      };
    });
  }

  if (PRIMITIVE_SCHEMA_TYPE.includes(input.type)) {
    return [
      {
        type: input.type,
        options: input.enum,
        description: input.description ?? null,
        required: required || input.required,
        example: input.example,
      },
      ...nullableArray(input),
    ];
  }

  if (input?.type === 'object') {
    return [
      {
        type: 'object',
        description: input.description ?? null,
        example: input.example,
        properties: Object.entries(input?.properties || {}).reduce(
          (acc, [key, value]) => ({
            ...acc,
            [key]: recursivelyConstructTypedRequestObject(
              value,
              spec,
              input.required?.includes(key),
            ),
          }),
          {},
        ),
      },
      ...nullableArray(input),
    ];
  }

  if (input.type === 'array') {
    return [
      {
        type: 'array',
        items: recursivelyConstructTypedRequestObject(input.items, spec),
        required: required || input.required,
        example: input.example,
        description: input.description ?? null,
      },
      ...nullableArray(input),
    ];
  }

  if (Array.isArray(input.type)) {
    return [
      ...input.type.map((type: string) => ({
        type,
        options: input.enum,
        required: required || input.required,
        example: input.example,
        description: input.description ?? null,
      })),
      ...nullableArray(input),
    ];
  }

  return [
    {
      type: input.type || 'string',
      options: input.enum,
      required: required || input.required,
      example: input.example,
      description: input.description ?? null,
    },
    ...nullableArray(input),
  ];
};

const recursivelyConstructRequestObject = (
  input: any,
  spec?: OpenAPIV3.Document,
): any => {
  if (input.$ref) {
    return recursivelyConstructRequestObject(
      resolveRef(input.$ref, spec),
      spec,
    );
  }

  if (PRIMITIVE_SCHEMA_TYPE.includes(input.type)) {
    return input.example ?? '';
  }

  if (input.oneOf?.length) {
    const [first] = input.oneOf;

    return recursivelyConstructRequestObject(first, spec);
  }

  if (input.type === 'object') {
    return Object.entries(input?.properties || {}).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: recursivelyConstructRequestObject(value, spec),
      }),
      {},
    );
  }

  if (input.type === 'array') {
    return [recursivelyConstructRequestObject(input.items, spec)];
  }

  return null;
};

const getRequestObjectFromContent = (
  mode: 'typed' | 'untyped',
  content?: { [media: string]: OpenAPIV3.MediaTypeObject },
  spec?: OpenAPIV3.Document,
) => {
  const { schema } = content?.['application/json'] || {};

  if (!schema) {
    return null;
  }

  return mode === 'typed'
    ? recursivelyConstructTypedRequestObject(schema, spec)
    : recursivelyConstructRequestObject(schema, spec);
};

export const parseRequestBody = (
  mode: 'typed' | 'untyped',
  requestBody?: RequestBodyWithRef | undefined,
  spec?: OpenAPIV3.Document,
) => {
  if (!requestBody || !spec) {
    return null;
  }

  const { $ref } = requestBody;

  if ($ref) {
    const [, ...pathToSchema] = $ref.split('/');
    const body: OpenAPIV3.RequestBodyObject = get(spec, pathToSchema, {});
    return getRequestObjectFromContent(mode, body.content, spec);
  }

  return getRequestObjectFromContent(mode, requestBody.content, spec);
};
