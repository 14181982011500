import { Category } from './types';

export const categoryLabel: Record<Category, string> = {
  earnings: 'Earnings',
  employee_deductions: 'Deductions',
  employer_contributions: 'Contributions',
  taxes: 'Taxes',
};

export const categoryOrder: Category[] = [
  'earnings',
  'employee_deductions',
  'employer_contributions',
  'taxes',
];
