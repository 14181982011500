import { HTMLInputTypeAttribute, useImperativeHandle, useState } from 'react';
import {
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  InputGroup,
  InputRightElement,
  IconButton,
} from '@chakra-ui/react';
import {
  type RegisterOptions,
  type Path,
  type UseFormRegister,
} from 'react-hook-form';
import { VisibilityIcon } from '../shared/icons/Visibility';
import { COLORS } from '../constant';

export type FormInputProps<FormData extends Record<string, unknown>> = {
  id: Path<FormData>;
  label: string;
  register: UseFormRegister<FormData>;
  error?: string;
  placeholder?: string;
  helpText?: string;
  rightElement?: React.ReactNode;
  inputRef?: React.RefObject<HTMLInputElement>;
  readOnly?: boolean;
  type?: HTMLInputTypeAttribute;
  registerOptions?: RegisterOptions<FormData>;
};

export const FormInput = <FormData extends Record<string, unknown>>({
  id,
  label,
  placeholder,
  helpText,
  inputRef,
  register,
  error,
  readOnly,
  type,
  registerOptions = {},
}: FormInputProps<FormData>) => {
  const { ref, ...inputProps } = register(id, registerOptions);
  const isPassword = type === 'password';
  const [showPassword, setShowPassword] = useState(false);

  useImperativeHandle(ref, () => inputRef?.current);

  return (
    <FormControl isInvalid={!!error}>
      <FormLabel fontSize="sm" htmlFor={id}>
        {label}
      </FormLabel>
      <InputGroup>
        <Input
          id={id}
          placeholder={placeholder}
          {...inputProps}
          ref={inputRef || ref}
          readOnly={readOnly}
          isDisabled={readOnly}
          type={showPassword ? 'text' : type}
        />
        {isPassword && !readOnly && (
          <InputRightElement p="1">
            <IconButton
              size="sm"
              aria-label={showPassword ? 'Hide password' : 'Show password'}
              variant="icon"
              _hover={{ backgroundColor: 'gray.c200' }}
              icon={<VisibilityIcon show={showPassword} />}
              onClick={() => setShowPassword(!showPassword)}
            />
          </InputRightElement>
        )}
      </InputGroup>
      {helpText && (
        <FormHelperText lineHeight="1.5" color={COLORS.GRAY.GRAY_600}>
          {helpText}
        </FormHelperText>
      )}
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
};
