import { ReactNode, useEffect, useState } from 'react';
import type { OpenAPIV3 } from 'openapi-types';
import {
  Box,
  Button,
  ButtonProps,
  Center,
  HStack,
  IconButton,
  IconButtonProps,
  Input,
  Select,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import {
  ArraySchemaProperty,
  PRIMITIVE_SCHEMA_TYPE,
  PrimitiveSchemaType,
  JsonSchemaSelections,
  JsonSchemaProperty,
  ObjectSchemaProperty,
} from '../open-api';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { COLORS } from 'constant';
import { PlusIcon } from 'shared/icons/PlusIcon';
import { AltTrashIcon } from 'shared/icons/AltTrashIcon';
import { AltInfoIcon } from 'shared/icons/AltInfoIcon';

const SchemasWithInput = ['string', 'number', 'integer', 'boolean'];

const AddPropertyButton = ({ children, ...props }: ButtonProps) => {
  return (
    <Button
      size="xs"
      fontSize="13px"
      variant="outline"
      gap="2"
      fontWeight="400"
      borderStyle="dashed"
      borderColor={COLORS.GRAY.GRAY_400}
      _hover={{ bg: 'transparent', borderColor: COLORS.GRAY.GRAY_500 }}
      {...props}
    >
      <PlusIcon />
      <span>{children}</span>
    </Button>
  );
};

const BorderIcon = (props: IconButtonProps) => (
  <IconButton
    variant="link"
    p="0"
    paddingInline="0"
    paddingBlock="0"
    w="20px"
    minW="0"
    color={COLORS.GRAY.GRAY_500}
    {...props}
  />
);

const useArray = ({
  name,
  type,
}: {
  name: string;
  type:
    | OpenAPIV3.NonArraySchemaObjectType
    | OpenAPIV3.ArraySchemaObjectType
    | 'null';
}) => {
  const { control, setValue, getValues } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  const flatFields = (getValues(name) as Array<PrimitiveSchemaType>) || [];

  const appendFlat = (item: PrimitiveSchemaType | null) => {
    setValue(name, [...flatFields, item]);
  };

  const removeFlat = (index: number) => {
    setValue(
      name,
      flatFields.filter((_, i) => i !== index),
    );
  };

  return PRIMITIVE_SCHEMA_TYPE.includes(type as PrimitiveSchemaType)
    ? {
        fields: flatFields,
        append: appendFlat,
        remove: removeFlat,
      }
    : {
        fields,
        append,
        remove,
      };
};

const getBorderTop = ({
  isSchemaWithInput,
  removeBorder,
}: {
  removeBorder: boolean | undefined;
  isSchemaWithInput: boolean;
}) => {
  if (isSchemaWithInput) {
    return '12px';
  }

  if (removeBorder) {
    return '-6px';
  }

  return '-8px';
};

const Border = ({
  children,
  propertyKey,
  formPath,
  removeBorder,
  deleteField,
  onDelete,
  setSchema,
  schemas,
  selectedSchema,
}: {
  children: ReactNode;
  propertyKey?: string;
  formPath: string;
  removeBorder?: boolean;
  deleteField?: () => void;
  onDelete?: () => void;
  schemas: JsonSchemaSelections;
  selectedSchema: JsonSchemaProperty;
  setSchema: (schema: JsonSchemaProperty) => void;
}) => {
  const { setValue, watch } = useFormContext();

  const fieldValue = watch(formPath);

  const isSchemaWithInput = SchemasWithInput.includes(selectedSchema.type);

  return (
    <Box
      pos="relative"
      {...(!removeBorder && {
        px: '12px',
        pt: '4',
        pb: '12px',
        border: '1px solid',
        borderColor: COLORS.GRAY.GRAY_400,
        borderRadius: '8',
      })}
    >
      <HStack
        fontWeight="normal"
        pos="absolute"
        bg="white"
        px="2px"
        fontSize="12px"
        fontFamily="Roboto Mono"
        spacing="1"
        top={removeBorder ? '-9px' : '-11px'}
        left="10px"
        display="flex"
        align="center"
        zIndex="2"
      >
        {propertyKey && (
          <Text>
            <Box as="strong">{propertyKey}</Box>
          </Text>
        )}
        {schemas.length > 1 ? (
          <Box
            sx={{
              '.chakra-select__icon-wrapper': { w: 4, right: 0 },
              select: { pr: 4 },
            }}
            color={COLORS.GRAY.GRAY_600}
          >
            <Select
              variant="unstyled"
              border="none"
              size="xs"
              fontSize="12px"
              value={selectedSchema.title ?? selectedSchema.type}
              iconSize="xs"
              onChange={(e) => {
                const schema = schemas.find(
                  (s) =>
                    s.title === e.target.value || s.type === e.target.value,
                );

                setValue(formPath, undefined);

                if (schema) setSchema(schema);
              }}
            >
              {schemas.map((schema) => {
                const value = schema.title ?? schema.type;

                return (
                  <option
                    key={value}
                    value={value}
                    onClick={() => setSchema(schema)}
                  >
                    {value}
                  </option>
                );
              })}
            </Select>
          </Box>
        ) : (
          <Text color={COLORS.GRAY.GRAY_600}>{selectedSchema.type}</Text>
        )}
        {'required' in selectedSchema && selectedSchema.required && (
          <Text color="red.500" fontSize="10px">
            *
          </Text>
        )}
      </HStack>
      <HStack
        pos="absolute"
        bg="white"
        right="2"
        align="center"
        justify="center"
        zIndex="2"
        gap="0.5"
        top={getBorderTop({ isSchemaWithInput, removeBorder })}
      >
        {(deleteField || fieldValue !== undefined) && (
          <Center boxSize="20px">
            <BorderIcon
              icon={<AltTrashIcon />}
              aria-label="Delete"
              onClick={() => {
                if (onDelete) {
                  onDelete();
                }

                if (deleteField) {
                  deleteField();
                  return;
                }

                setValue(formPath, undefined);
              }}
            />
          </Center>
        )}
        {selectedSchema.description && (
          <Tooltip label={selectedSchema.description}>
            <Center boxSize="20px">
              <BorderIcon icon={<AltInfoIcon />} aria-label="Info" />
            </Center>
          </Tooltip>
        )}
      </HStack>
      {children}
    </Box>
  );
};

const ArrayInput = ({
  propertyKey,
  formPath,
  schema,
}: {
  propertyKey?: string;
  formPath: string;
  schema: ArraySchemaProperty;
}) => {
  const { fields, append, remove } = useArray({
    name: formPath,
    type: schema.items?.[0]?.type || 'string',
  });

  return (
    <Stack spacing="4">
      {fields.map((field, index) => (
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        <JsonBuilder
          key={field && typeof field === 'object' ? field.id : index}
          jsonSchemas={schema.items}
          formPath={`${formPath}.${index}`}
          deleteField={() => remove(index)}
        />
      ))}
      <AddPropertyButton onClick={() => append(null)}>
        <>
          Add{' '}
          <Box as="strong" fontFamily="Roboto Mono">
            {propertyKey}
          </Box>{' '}
          item
        </>
      </AddPropertyButton>
    </Stack>
  );
};

const NullInput = ({ formPath }: { formPath: string }) => {
  const { setValue } = useFormContext();

  useEffect(() => {
    setValue(formPath, null);
  }, [formPath, setValue]);

  return (
    <Input
      type="text"
      borderColor="gray.400"
      size="xs"
      borderRadius="8"
      readOnly
      value="null"
    />
  );
};

const ObjectInput = ({
  formPath,
  schema,
}: {
  formPath: string;
  schema: ObjectSchemaProperty;
}) => {
  const [customProperties, setCustomProperties] = useState<string[]>([]);
  const [newPropertyName, setNewPropertyName] = useState('');

  const addCustomProperty = () => {
    if (newPropertyName && !customProperties.includes(newPropertyName)) {
      setCustomProperties([...customProperties, newPropertyName]);
      setNewPropertyName('');
    }
  };

  const formKey = `${schema.title}-${schema.type}`;

  return (
    <Stack spacing="4">
      {Object.entries(schema.properties || {}).map(([key, value]) => (
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        <JsonBuilder
          key={`${key}-${formKey}`}
          jsonSchemas={value}
          propertyKey={key}
          formPath={`${formPath}.${key}`}
        />
      ))}

      {customProperties.map((key) => (
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        <JsonBuilder
          key={`custom-${key}-${formKey}`}
          jsonSchemas={[
            { type: 'string', description: null },
            { type: 'number', description: null },
            { type: 'object', description: null, properties: {} },
            {
              type: 'array',
              description: null,
              items: [
                { type: 'object', description: null, properties: {} },
                { type: 'string', description: null },
                { type: 'boolean', description: null },
                { type: 'number', description: null },
                { type: 'integer', description: null },
              ],
            },
            { type: 'boolean', description: null },
            { type: 'integer', description: null },
            { type: 'null', description: null },
          ]}
          propertyKey={key}
          formPath={`${formPath}.${key}`}
          onDelete={() => {
            setCustomProperties(customProperties.filter((k) => k !== key));
          }}
        />
      ))}

      <HStack>
        <Input
          size="xs"
          borderRadius="8"
          flexBasis="60%"
          placeholder="Property name"
          value={newPropertyName}
          onChange={(e) => setNewPropertyName(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && addCustomProperty()}
        />
        <AddPropertyButton
          onClick={addCustomProperty}
          flexBasis="40%"
          isDisabled={!newPropertyName}
        >
          Add Property
        </AddPropertyButton>
      </HStack>
    </Stack>
  );
};

export const JsonBuilder = ({
  jsonSchemas,
  propertyKey,
  formPath,
  deleteField,
  onDelete,
}: {
  jsonSchemas: JsonSchemaSelections;
  propertyKey?: string;
  formPath: string;
  deleteField?: () => void;
  onDelete?: () => void;
}) => {
  const { register, setValue } = useFormContext();

  const [schema, setSchema] = useState(jsonSchemas[0]);

  // Set default value for the schema
  useEffect(() => {
    if (!schema) return;

    const setDefaultValue = () => {
      if (schema.type === 'string' && schema.options) {
        setValue(formPath, schema.options[0]);
        return;
      }

      if (schema.example !== undefined) {
        setValue(formPath, schema.example);
        return;
      }

      switch (schema.type) {
        case 'string':
        case 'number':
        case 'integer':
          setValue(formPath, undefined);
          break;
        case 'boolean':
          setValue(formPath, true);
          break;
        case 'null':
          setValue(formPath, null);
          break;
      }
    };

    setDefaultValue();
  }, [schema, formPath, setValue]);

  if (!schema) {
    return null;
  }

  const componentKey = `${schema.type}-${formPath}`;

  return (
    <Border
      propertyKey={propertyKey}
      formPath={formPath}
      deleteField={deleteField}
      onDelete={onDelete}
      setSchema={setSchema}
      selectedSchema={schema}
      schemas={jsonSchemas}
      removeBorder={schema.type !== 'object' && schema.type !== 'array'}
    >
      {schema.type === 'object' ? (
        <Stack spacing="4">
          <ObjectInput formPath={formPath} schema={schema} />
        </Stack>
      ) : schema.type === 'array' ? (
        <ArrayInput
          propertyKey={propertyKey}
          formPath={formPath}
          schema={schema}
        />
      ) : schema.type === 'boolean' ? (
        <Select
          borderColor={COLORS.GRAY.GRAY_400}
          size="md"
          fontSize="12px"
          borderRadius="8"
          key={componentKey}
          {...register(formPath, {
            setValueAs: (value) => value === 'true' || value === true,
          })}
        >
          {['true', 'false'].map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </Select>
      ) : schema.type === 'string' && schema.options ? (
        <Select
          borderColor={COLORS.GRAY.GRAY_400}
          size="md"
          fontSize="12px"
          borderRadius="8"
          key={componentKey}
          {...register(formPath)}
        >
          {schema.options
            .filter((option) => option !== null)
            .map((option) => (
              <option key={option.toString()} value={option.toString()}>
                {option}
              </option>
            ))}
        </Select>
      ) : ['string', 'number', 'integer'].includes(schema.type) ? (
        <Input
          type={schema.type === 'string' ? 'text' : 'number'}
          size="md"
          fontSize="12px"
          borderRadius="8"
          fontFamily="Roboto Mono"
          px="12px"
          pr="36px"
          key={componentKey}
          borderColor={COLORS.GRAY.GRAY_400}
          placeholder={`Enter ${propertyKey || 'value'}`}
          {...register(formPath, {
            setValueAs: (value) => {
              if (schema.type === 'number' || schema.type === 'integer') {
                return Number(value);
              }

              return value;
            },
          })}
        />
      ) : schema.type === 'null' ? (
        <NullInput formPath={formPath} />
      ) : null}
    </Border>
  );
};
