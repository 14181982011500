import { z } from 'zod';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { useToast } from '@chakra-ui/react';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';
import { baseURL } from '../../utils/baseUrls';
import { useApplication } from '../../applications/hooks';
import { useSandboxStore } from '../state/sandboxStore';

export type Scope = { key: string; label: string; selected: boolean };

export type ConnectSessionRow = {
  customerId: string;
  customerName: string;
  customerEmail: string;
  sessionId: string;
  connectUrl: string;
};

export type CreateBulkConnectSessionResponse = {
  rows: ConnectSessionRow[];
};

export const csvRowSchema = z.object({
  customerId: z.string().min(1, { message: 'Company ID is required' }),
  customerName: z.string().min(1, { message: 'Company Name is required' }),
  customerEmail: z.string().email().optional(),
});

export const newBulkSessionSchema = z.object({
  products: z.array(z.string()),
  rows: z.array(csvRowSchema),
  redirectUri: z.string().url().optional(),
});

export type NewBulkSession = z.infer<typeof newBulkSessionSchema>;

export const useBulkConnectSession = () => {
  const toast = useToast();
  const { applicationId } = useParams<{ applicationId: string }>();
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();
  const { application } = useApplication(applicationId);
  const selectedSandbox = useSandboxStore((store) => store.selectedSandbox);

  const create = useMutation<
    CreateBulkConnectSessionResponse,
    AxiosError,
    NewBulkSession
  >({
    mutationFn: async (session: NewBulkSession) => {
      const uri = new URL('/api/v1/connect-session/bulk', baseURL);

      return axios
        .post(
          uri.toString(),
          {
            ...session,
            clientId: applicationId,
            sandbox: application?.isSandbox() ? selectedSandbox : undefined,
          },
          {
            headers: {
              Authorization: `Bearer ${await getAccessTokenSilently()}`,
            },
          },
        )
        .then((res) => res.data);
    },
    onSuccess: () => {
      toast({
        title: 'Finch Connect URLs created successfully',
        status: 'success',
        isClosable: true,
      });

      queryClient.invalidateQueries({
        queryKey: ['connect-sessions', applicationId],
      });

      queryClient.invalidateQueries({
        queryKey: ['staged-connections', applicationId],
      });
    },
  });

  return { create };
};
