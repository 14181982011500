import { parse } from 'papaparse';
import { useState } from 'react';
import { useDropzone, FileRejection } from 'react-dropzone';

const validateCsvFile = async <Row extends Record<string, string>>(opts: {
  file: File | null;
  validations?: Array<(rows: Row[]) => string | null>;
  headerMap?: Record<string, string>;
}): Promise<{ error: string | null; data: Row[] }> => {
  const { file, validations, headerMap } = opts;
  if (!file?.name) return { error: null, data: [] };

  return new Promise((resolve) => {
    parse<Row>(file, {
      skipEmptyLines: 'greedy',
      header: true,
      transformHeader: (header) => headerMap?.[header] || header.trim(),
      complete: (results) => {
        if (results.errors.length > 0) {
          resolve({
            error:
              'The CSV is not properly formatted. Please check that your file follows the format in the sample file below',
            data: [],
          });
          return;
        }

        if (validations) {
          for (const validate of validations) {
            const validateError = validate(results.data);

            if (validateError) {
              resolve({
                error: validateError,
                data: [],
              });
              return;
            }
          }
        }

        resolve({
          error: null,
          data: results.data,
        });
      },
    });
  });
};

export const useCsvFileUpload = <Row extends Record<string, string>>({
  validations,
  headerMap,
}: {
  validations?: Array<(rows: Row[]) => string | null>;
  headerMap?: Record<string, string>;
} = {}) => {
  const [csvFile, setCsvFile] = useState<File | null>(null);
  const [jsonData, setJsonData] = useState<Row[] | null>(null);
  const [error, setError] = useState<string | null>(null);

  const onDropRejected = (rejectedFiles: FileRejection[]) => {
    if (rejectedFiles.length > 1) {
      setError('Please upload a single CSV file');
      return;
    }

    const fileError = rejectedFiles[0]?.errors[0];

    if (fileError?.code === 'file-too-large') {
      setError(
        'The CSV file is too large. Please check that your file follows the format in the sample file below.',
      );
      return;
    }

    if (fileError?.code === 'file-invalid-type') {
      setError('Please upload a CSV file');
      return;
    }

    setError(fileError?.message || null);
  };

  const dropzone = useDropzone({
    maxFiles: 1,
    accept: { 'text/csv': ['.csv'] },
    maxSize: 100 * 1024, // 100KB
    onDropRejected,
    onDropAccepted: async ([file]) => {
      if (!file) return;

      const { error, data } = await validateCsvFile({
        file,
        validations,
        headerMap,
      });

      if (error) {
        setError(error);
        return;
      }

      setError(null);
      setCsvFile(file);
      setJsonData(data);
    },
  });

  const removeFile = () => {
    setCsvFile(null);
    setJsonData(null);
    setError(null);
  };

  return { dropzone, removeFile, jsonData, error, csvFile };
};
