import './reset.css';

import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import { useFlag } from '@unleash/proxy-client-react';
import {
  Center,
  ChakraProvider,
  ColorModeScript,
  Spinner,
} from '@chakra-ui/react';
import { IconContext } from 'react-icons';
import { User, useAuth0 } from '@auth0/auth0-react';
import type { Auth0ContextInterface } from '@auth0/auth0-react';
import { datadogRum } from '@datadog/browser-rum';

import { CredentialsPage } from './credentials/pages/CredentialsPage';
import { ConnectionsPage } from './connections/pages';
import { ConnectionsPageV2 } from './connections-v2/pages';
import { ConnectionDetail } from './connection-detail-v2/pages';
import { StagedConnectionDetail } from './connection-detail-staged/pages';
import { theme } from './shared/theme';
import { PageLayout } from './shared/PageLayout';
import { ApplicationsRedirectPage } from './credentials/pages/ApplicationsRedirectPage';
import { NotFoundPage } from './NotFoundPage';
import { LogsPage } from './activity/LogsPage';
import { DatePickerTheme } from './shared/DatePickerGlobalStyles';
import { Signup } from './auth/signup/new/Signup';
import { Verification } from './auth/signup/verification/Verification';
import { AuthCallback } from './auth/AuthCallback';
import { Organization } from './shared/OrganizationContext';
import { Login } from './auth/Login';
import { Logout } from './auth/Logout';
import { WebhooksPage, WebhookEndpointPage } from './webhooks/pages';
import { RegisterLoginEventAndRedirectToApp } from './components/RegisterLoginEventAndRedirectToApp';
import { DataSyncPage } from './jobs/pages';
import { useSetupUnleashContext } from './shared/hooks/useSetupUnleashContext';
import { useMaintenanceCheck } from './shared/hooks/useMaintenanceCheck';
import { ConfigurePage as IntegrationConfigurePage } from './integrations/pages/ConfigurePage';
import { FieldSupportPage } from './docs/pages/FieldSupportPage';
import { useApplication } from './applications/hooks';
import { SettingsRouter } from './settings/pages/OrgSettingsPage';
import { analyticsClient } from './utils/analytics/client';
import { usePermissions } from './hooks/use-permissions';
import { PERMISSION } from './constant/roles-and-permissions';
import { FeatureFlag } from './constant/feature-flags';
import { PublicPayStatementMapping } from './paystatement-mapping/pages/PublicPayStatementMapping';

const RedirectToLogin = () => <Redirect to="/login" />;

const SpecificApplicationRouter = () => {
  const { applicationId } = useParams<{ applicationId: string }>();
  const { isPending, error } = useApplication();
  const roleCanViewWebhooks = usePermissions([PERMISSION.Webhooks.Read]);
  const connectionsV2Enabled = useFlag(FeatureFlag.DashboardConnectionsV2);

  if (error?.response?.status === 404) {
    return <Redirect to="/app/applications" />;
  }

  if (isPending) {
    return (
      <Center p="10">
        <Spinner title="Application page loading" />
      </Center>
    );
  }

  return (
    <Switch>
      <Route exact path="/app/applications/:applicationId/credentials">
        <CredentialsPage />
      </Route>

      {connectionsV2Enabled ? (
        <Route exact path="/app/applications/:applicationId/connections">
          <ConnectionsPageV2 />
        </Route>
      ) : (
        <Route exact path="/app/applications/:applicationId/connections">
          <ConnectionsPage />
        </Route>
      )}

      <Route exact path="/app/applications/:applicationId/integrations">
        <IntegrationConfigurePage />
      </Route>

      {connectionsV2Enabled ? (
        <Route
          exact
          path="/app/applications/:applicationId/connections/:connectionId"
        >
          <ConnectionDetail />
        </Route>
      ) : (
        <Route
          exact
          path="/app/applications/:applicationId/connections/:companyId"
        >
          <DataSyncPage />
        </Route>
      )}

      {connectionsV2Enabled && (
        <Route
          exact
          path="/app/applications/:applicationId/incomplete-connections/:stagedConnectionId"
        >
          <StagedConnectionDetail />
        </Route>
      )}

      {roleCanViewWebhooks && (
        <Route exact path="/app/applications/:applicationId/webhooks">
          <WebhooksPage />
        </Route>
      )}

      {roleCanViewWebhooks && (
        <Route
          exact
          path="/app/applications/:applicationId/webhooks/:endpointId"
        >
          <WebhookEndpointPage />
        </Route>
      )}

      <Route exact path="/app/applications/:applicationId/activity">
        <LogsPage />
      </Route>

      <Route path="*">
        <Redirect to={`/app/applications/${applicationId}/credentials`} />
      </Route>
    </Switch>
  );
};

const DashboardApp = () => {
  const { isAuthenticated, isLoading, user }: Auth0ContextInterface<User> =
    useAuth0();

  datadogRum.setUser({
    id: user?.sub,
    email: user?.email,
    name: `${user?.given_name} ${user?.family_name}`,
  });

  if (user?.email) {
    analyticsClient.setUserId(user.email);
  }

  useSetupUnleashContext();

  if (isLoading) {
    return null;
  }
  // This is to prevent the user from being sent to the login screen after refreshing the page.
  // https://community.auth0.com/t/how-do-i-use-organizations-with-the-react-sdk-and-maintain-user-sessions/69949

  return (
    <>
      {isAuthenticated ? (
        <PageLayout>
          <Switch>
            {/* All of the routes below require the user to be signed in! */}
            <Route exact path="/app">
              <Redirect to="/app/applications" />
            </Route>

            <Route exact path="/app/applications">
              <ApplicationsRedirectPage />
            </Route>

            <Route path="/app/applications/:applicationId">
              <SpecificApplicationRouter />
            </Route>

            <Route path="/app/settings">
              <SettingsRouter />
            </Route>

            <Route path="*">
              <NotFoundPage />
            </Route>
          </Switch>
        </PageLayout>
      ) : (
        <RedirectToLogin />
      )}
    </>
  );
};

const DocumentationComponents = () => {
  return (
    <Switch>
      <Route exact path="/docs/components/field-support">
        <FieldSupportPage />
      </Route>
      <Route path="*">
        <NotFoundPage />
      </Route>
    </Switch>
  );
};

export const App = () => {
  const { isAuthenticated }: Auth0ContextInterface<User> = useAuth0();
  const paystatementMappingEnabled = useFlag(FeatureFlag.PayStatementMapping);

  useMaintenanceCheck();

  return (
    <>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <ChakraProvider theme={theme}>
        <IconContext.Provider value={{ size: '16px' }}>
          <DatePickerTheme />
          <Organization>
            <Switch>
              <Route exact path="/">
                {/* TODO: maybe in the future this will have a homepage? */}
                {isAuthenticated && <RegisterLoginEventAndRedirectToApp />}
                {!isAuthenticated && <Redirect to="/signup" />}
              </Route>

              <Route exact path="/login">
                <Login />
              </Route>

              <Route exact path="/logout">
                <Logout />
              </Route>

              <Route exact path="/signup">
                <Signup />
              </Route>

              <Route exact path="/signup/verification">
                <Verification />
              </Route>

              <Route path="/auth/callback">
                <AuthCallback />
              </Route>

              <Route path="/app">
                <DashboardApp />
              </Route>

              <Route path="/docs/components">
                <DocumentationComponents />
              </Route>

              {paystatementMappingEnabled && (
                <Route path="/employer/pay-statement-mapping/:code">
                  <PublicPayStatementMapping />
                </Route>
              )}
              <Route path="*">
                <NotFoundPage />
              </Route>
            </Switch>
          </Organization>
        </IconContext.Provider>
      </ChakraProvider>
    </>
  );
};
