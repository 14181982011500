import {
  Button,
  Grid,
  GridItem,
  Link,
  Spinner,
  Stack,
  StackDivider,
} from '@chakra-ui/react';
import { TwoFactorAuthentication } from '../components/TwoFactorAuthentication';
import { ContentBox } from '../../components/ContentBox';
import { SubSectionHeading } from '../../shared/Typography';
import { COLORS } from '../../constant';
import { Switch } from '../../components/Switch';
import { useOrganization } from '../../shared/hooks/use-organization';
import { SettingEntry } from '../components/SettingEntry';
import { PERMISSION } from '../../constant/roles-and-permissions';
import { PermissionRequired } from '../../components/PermissionRequired';
import { useEffect, useState } from 'react';
import { OktaOIDC } from '../components/SingleSignOn/OktaOIDC';
import { useFlag } from '@unleash/proxy-client-react';
import { FeatureFlag } from '../../constant/feature-flags';
import { Modal } from '../../components/Modal';

type DeleteSsoModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  confirmDeletion: () => void;
};

const DeleteSsoModal = ({
  isOpen,
  closeModal,
  confirmDeletion,
}: DeleteSsoModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {}}
      header="You’re removing SSO from your account"
      body={
        <>
          <p>
            If you save these settings, SSO will no longer be a sign-in option
            for you or anyone on your team.
          </p>
          <br />
          <p>Are you sure you want to remove SSO?</p>
        </>
      }
      footer={
        <Stack direction="row" spacing={4}>
          <Button variant="outline" onClick={closeModal}>
            Go back
          </Button>
          <Button
            colorScheme="red"
            onClick={() => {
              closeModal();
              confirmDeletion();
            }}
          >
            Yes, remove
          </Button>
        </Stack>
      }
    />
  );
};

export const SecuritySettingsPage = () => {
  const {
    organization,
    updateMfaSettings,
    getSsoConfigurations,
    deleteSsoConfiguration,
  } = useOrganization();
  const [isSsoEnabled, setIsSsoEnabled] = useState(false);
  const [isConfirmRemoveOpen, setIsConfirmRemoveOpen] = useState(false);

  const showSso = useFlag(FeatureFlag.DashboardSsoSelfService);

  useEffect(() => {
    setIsSsoEnabled(!!getSsoConfigurations.data?.length);
  }, [getSsoConfigurations.data]);

  return (
    <Stack spacing={8}>
      <ContentBox>
        <Stack
          divider={<StackDivider borderColor={COLORS.GRAY.GRAY_400} />}
          spacing={0}
        >
          <SubSectionHeading px="6" py="4">
            Multi-Factor Authentication
          </SubSectionHeading>
          <PermissionRequired match={[PERMISSION.OrgMfaSettings.Update]}>
            <SettingEntry
              title="Enforce Multi-Factor Authentication For All Organization Members"
              details="When turned on, all members on your team will be required to set
              up Multi-Factor Authentication for their account."
              action={
                organization.isPending || updateMfaSettings.isPending ? (
                  <Spinner />
                ) : (
                  <Switch
                    enabled={organization.data?.mfa?.required ?? false}
                    id="organization-mfa"
                    onChange={() => {
                      updateMfaSettings.mutate({
                        required: !organization.data?.mfa?.required,
                      });
                    }}
                    labels={{ enabled: 'On', disabled: 'Off' }}
                  />
                )
              }
            />
          </PermissionRequired>
          <TwoFactorAuthentication />
        </Stack>
      </ContentBox>

      {showSso && (
        <ContentBox>
          <Stack
            divider={<StackDivider borderColor={COLORS.GRAY.GRAY_400} />}
            spacing={0}
          >
            <SubSectionHeading px="6" py="4">
              Okta Single Sign-On (SSO)
            </SubSectionHeading>
            <Grid templateColumns="repeat(2, 1fr)" gap={4}>
              <GridItem colSpan={2}>
                <SettingEntry
                  title="OpenID Connect (OIDC)"
                  details={
                    <>
                      When turned on, all members on your team will be required
                      to sign in via Okta SSO.
                      <br />
                      <br />
                      <>
                        Need more help? Check out our{' '}
                        <Link
                          href="https://support.tryfinch.com/hc/en-us/articles/33477510359316-How-to-Implement-Okta-SSO"
                          target="_blank"
                          textDecoration="underline"
                          textColor={COLORS.FINCH.BLACK}
                        >
                          Help Center
                        </Link>{' '}
                        for more instructions.
                      </>
                    </>
                  }
                  action={
                    getSsoConfigurations.isPending ? (
                      <Spinner />
                    ) : (
                      <Switch
                        labels={{ enabled: 'On', disabled: 'Off' }}
                        enabled={isSsoEnabled}
                        id="organization-sso"
                        onChange={(enabled) => {
                          if (!enabled) {
                            setIsConfirmRemoveOpen(true);
                          } else {
                            setIsSsoEnabled(!isSsoEnabled);
                          }
                        }}
                      />
                    )
                  }
                />
              </GridItem>
              <GridItem colStart={2}>
                {isSsoEnabled && (
                  <OktaOIDC
                    initFormData={
                      getSsoConfigurations.data?.[0]?.options ?? null
                    }
                    onFormUpdated={() => {}}
                  />
                )}
              </GridItem>
            </Grid>
          </Stack>
        </ContentBox>
      )}
      <DeleteSsoModal
        isOpen={isConfirmRemoveOpen}
        closeModal={() => setIsConfirmRemoveOpen(false)}
        confirmDeletion={() => {
          if (getSsoConfigurations.data?.length) {
            deleteSsoConfiguration.mutate(
              getSsoConfigurations.data?.[0]?.authConnectionId ?? '',
            );
          }
          setIsSsoEnabled(!isSsoEnabled);
        }}
      />
    </Stack>
  );
};
