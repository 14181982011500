import {
  Button,
  FormControl,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { SettingEntry } from '../SettingEntry';
import { CopyIcon } from '../../../shared/icons/CopyIcon';
import { ChangeEventHandler, useEffect, useState } from 'react';
import { SsoConfiguration } from '../../../auth/organization-client';
import { useOrganization } from '../../../shared/hooks/use-organization';
import { AltTrashIcon } from '../../../shared/icons/AltTrashIcon';
import { TransparentIconButton } from '../../../shared/TransparentIconButton';
import { LoadingModal } from '../../../components/Loading/LoadingModal';
import { Modal } from '../../../components/Modal';

const OktaOIDCFormKeys = [
  'client_id',
  'client_secret',
  'issuer',
  'domain_aliases',
] as const;

type OktaOIDCFormKeys = (typeof OktaOIDCFormKeys)[number];

type OktaOIDCFormErrors = Partial<Record<OktaOIDCFormKeys, string>>;

const EditableInput = ({
  value,
  error,
  onChange,
  type,
}: {
  error?: string;
  value?: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  type?: React.HTMLInputTypeAttribute;
}) => {
  return (
    <>
      <InputGroup>
        <FormControl>
          <Input
            type={type}
            isInvalid={!!error}
            errorBorderColor="error.500"
            value={value}
            onChange={onChange}
          />
        </FormControl>
      </InputGroup>
      <Text color="error.500" fontSize="sm" mt={1}>
        {error}
      </Text>
    </>
  );
};

const EMPTY_FORM_DATA = {
  client_id: '',
  client_secret: '',
  issuer: '',
  domain_aliases: [''],
};

type ConfirmCancelModalProps = {
  resetFormData: () => void;
  closeModal: () => void;
  isOpen: boolean;
};

const ConfirmCancelModal = ({
  resetFormData,
  closeModal,
  isOpen,
}: ConfirmCancelModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {}}
      header="You have unsaved progress"
      body={
        <>
          <p>
            If you cancel, your progress will not be saved. You’ll have to input
            your credentials again.
          </p>
          <br />
          <p>Are you sure you want to cancel?</p>
        </>
      }
      footer={
        <Stack direction="row" spacing={4}>
          <Button variant="outline" onClick={() => closeModal()}>
            Go back
          </Button>
          <Button
            colorScheme="red"
            onClick={() => {
              resetFormData();
              closeModal();
            }}
          >
            Yes, cancel
          </Button>
        </Stack>
      }
    />
  );
};

export const OktaOIDC = ({
  initFormData,
  onFormUpdated,
}: {
  initFormData: SsoConfiguration['options'] | null;
  onFormUpdated: (data: SsoConfiguration['options']) => void;
}) => {
  const { updateSsoConfiguration } = useOrganization();
  const toast = useToast({
    duration: 3000,
    isClosable: true,
  });

  const [formData, setFormData] = useState<SsoConfiguration['options']>(
    initFormData ?? EMPTY_FORM_DATA,
  );

  const [errors, setErrors] = useState<OktaOIDCFormErrors>({});
  const [errorDomains, setErrorDomains] = useState<boolean[]>(
    (initFormData?.domain_aliases || ['']).map(() => false),
  );

  const [confirmCancel, setConfirmCancel] = useState(false);

  useEffect(() => {
    if (initFormData) {
      setFormData(initFormData);
    }
  }, [initFormData]);

  useEffect(() => {
    onFormUpdated(formData);
    setErrors({});
    setErrorDomains(formData.domain_aliases?.map(() => false) ?? []);
  }, [formData, onFormUpdated, setErrors, setErrorDomains]);

  const isFieldMissing = (
    key: OktaOIDCFormKeys,
    formData: SsoConfiguration['options'],
  ) => {
    return !(key in formData) || !formData[key];
  };

  const isIssuerInvalid = (
    key: OktaOIDCFormKeys,
    formData: SsoConfiguration['options'],
  ) => {
    return (
      key === 'issuer' &&
      !RegExp(/^https:\/\/[^\s]+\.okta\.com$/).exec(formData[key] || '')
    );
  };

  const areDomainAliasesEmpty = (
    key: OktaOIDCFormKeys,
    formData: SsoConfiguration['options'],
  ) => {
    return (
      key === 'domain_aliases' &&
      Array.isArray(formData[key]) &&
      (formData[key]?.length === 0 || formData[key]?.every((domain) => !domain))
    );
  };

  const areDomainAliasesInvalid = (
    key: OktaOIDCFormKeys,
    formData: SsoConfiguration['options'],
  ) => {
    return (
      key === 'domain_aliases' &&
      Array.isArray(formData[key]) &&
      formData[key]?.some((domain) => domain?.includes('@'))
    );
  };

  const validate = () => {
    const validationErrors: OktaOIDCFormErrors = {};

    OktaOIDCFormKeys.forEach((key) => {
      if (isFieldMissing(key, formData)) {
        validationErrors[key] = 'This field is required';
      } else if (isIssuerInvalid(key, formData)) {
        validationErrors[key] =
          'URL should be formatted like "https://example.okta.com"';
      } else if (areDomainAliasesEmpty(key, formData)) {
        validationErrors[key] = 'At least one domain is required';
        setErrorDomains((formData.domain_aliases || []).map(() => true));
      } else if (areDomainAliasesInvalid(key, formData)) {
        validationErrors[key] = 'Domain should be formatted like "example.com"';
        setErrorDomains(
          (formData.domain_aliases || []).map((domain) =>
            domain?.includes('@'),
          ),
        );
      }
    });
    return validationErrors;
  };

  const handleSave = () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    updateSsoConfiguration.mutate({
      strategy: 'okta',
      options: {
        ...formData,
        domain_aliases: formData.domain_aliases ?? [],
      },
    });
  };

  return (
    <Stack spacing={0}>
      <SettingEntry
        title="Sign-In Redirect URIs"
        details=""
        action={
          <>
            <InputGroup>
              <Input
                value="https://login.tryfinch.com/login/callback"
                disabled
                pointerEvents="none"
              />
              <InputRightElement color="gray.500" pointerEvents="none">
                <CopyIcon />
              </InputRightElement>
            </InputGroup>

            <InputGroup>
              <Input
                value="https://dev-wafcpnzq.us.auth0.com/login/callback"
                disabled
                pointerEvents="none"
              />
              <InputRightElement color="gray.500" pointerEvents="none">
                <CopyIcon />
              </InputRightElement>
            </InputGroup>
          </>
        }
      />

      <SettingEntry
        title="Sign-Out Redirect URIs"
        details=""
        action={
          <>
            <InputGroup>
              <Input
                value="https://login.tryfinch.com"
                disabled
                pointerEvents="none"
              />
              <InputRightElement color="gray.500" pointerEvents="none">
                <CopyIcon />
              </InputRightElement>
            </InputGroup>

            <InputGroup>
              <Input
                value="https://dev-wafcpnzq.us.auth0.com"
                disabled
                pointerEvents="none"
              />
              <InputRightElement color="gray.500" pointerEvents="none">
                <CopyIcon />
              </InputRightElement>
            </InputGroup>
          </>
        }
      />

      <SettingEntry
        title="Client ID"
        details=""
        action={
          <EditableInput
            value={formData.client_id}
            onChange={(e) =>
              setFormData((formData) => ({
                ...formData,
                client_id: e.target.value,
              }))
            }
            error={errors.client_id}
          />
        }
      />

      <SettingEntry
        title="Client Secret"
        details=""
        action={
          <EditableInput
            type="password"
            value={formData.client_secret}
            onChange={(e) =>
              setFormData((formData) => ({
                ...formData,
                client_secret: e.target.value,
              }))
            }
            error={errors.client_secret}
          />
        }
      />

      <SettingEntry
        title="Okta Domain"
        details=""
        action={
          <EditableInput
            value={formData.issuer}
            onChange={(e) =>
              setFormData((formData) => ({
                ...formData,
                issuer: e.target.value,
              }))
            }
            error={errors.issuer}
          />
        }
      />

      <SettingEntry
        title="Provider Domain"
        details=""
        action={
          <>
            {(formData.domain_aliases?.length
              ? formData.domain_aliases
              : ['']
            ).map((domain, index) => (
              <InputGroup key={index} mb={2}>
                <FormControl>
                  <Input
                    isInvalid={!!errors.domain_aliases && errorDomains[index]}
                    errorBorderColor="error.500"
                    value={domain}
                    onChange={(e) => {
                      const newDomains = [...(formData.domain_aliases ?? [])];
                      newDomains[index] = e.target.value;
                      setFormData((formData) => ({
                        ...formData,
                        domain_aliases: newDomains,
                      }));
                    }}
                    placeholder="Enter Provider Domain"
                  />
                  {(formData.domain_aliases?.length ?? 1) > 1 && (
                    <InputRightElement color="gray.500">
                      <TransparentIconButton
                        variant="icon"
                        aria-label="Delete"
                        onClick={() => {
                          const newDomains = [
                            ...(formData.domain_aliases ?? []),
                          ];
                          newDomains.splice(index, 1);
                          setFormData((formData) => ({
                            ...formData,
                            domain_aliases: newDomains,
                          }));
                        }}
                      >
                        <AltTrashIcon />
                      </TransparentIconButton>
                    </InputRightElement>
                  )}
                </FormControl>
              </InputGroup>
            ))}
            <Button
              variant="link"
              size="sm"
              onClick={() => {
                const newDomains = [...(formData.domain_aliases ?? []), ''];
                setFormData((formData) => ({
                  ...formData,
                  domain_aliases: newDomains,
                }));
              }}
              isDisabled={formData.domain_aliases?.some((domain) => !domain)}
            >
              + Add another email domain
            </Button>
            <Text color="error.500" fontSize="sm" mt={1}>
              {errors.domain_aliases}
            </Text>
          </>
        }
      />

      <Stack
        direction="row"
        justifyContent="flex-end"
        spacing={4}
        px="6"
        py="4"
      >
        <Button variant="secondary" onClick={() => setConfirmCancel(true)}>
          Cancel
        </Button>
        <Button variant="primaryPurple" onClick={handleSave}>
          Save
        </Button>
      </Stack>
      <ConfirmCancelModal
        resetFormData={() => {
          setFormData(initFormData ?? EMPTY_FORM_DATA);
          toast({
            description: 'Your progress has been reset',
            status: 'info',
          });
        }}
        closeModal={() => setConfirmCancel(false)}
        isOpen={confirmCancel}
      />
      <LoadingModal
        message="We’re configuring your SSO settings. This might take up to 60 seconds."
        isOpen={updateSsoConfiguration.isPending}
      />
    </Stack>
  );
};
