import { Box, Flex } from '@chakra-ui/react';
import { PageHeading } from '../../shared/Typography';
import { PayStatementMapping } from '../../connection-detail-v2/pages/pay-statement-mapping/pages';

export const PublicPayStatementMapping = () => {
  return (
    <Box maxW={1200} mx={'auto'} py={10}>
      <Flex gap={8} flexDir={'column'}>
        <Flex flexDir={'column'} gap={2}>
          <PageHeading>Pay Statement Mapping</PageHeading>
        </Flex>
        <PayStatementMapping
          payStatementItem={[]}
          payStatementItemLabels={[]}
          isPayStatementsLoading={false}
          enableControls={false}
        />
      </Flex>
    </Box>
  );
};
