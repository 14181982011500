import {
  extendTheme,
  useFormControlContext,
  createMultiStyleConfigHelpers,
  type StyleFunctionProps,
} from '@chakra-ui/react';
import {
  alertAnatomy,
  menuAnatomy,
  radioAnatomy,
  switchAnatomy,
  tagAnatomy,
} from '@chakra-ui/anatomy';
import { COLORS, SIZES } from '../constant';

const tooltipTheme = {
  baseStyle: {
    borderRadius: '6px',
    backgroundColor: COLORS.FINCH.BLACK,
    padding: '10px',
    fontSize: '13px',
  },
};

const ModalTheme = {
  baseStyle: {
    header: {
      paddingTop: '24px',
    },
    footer: {
      paddingBottom: '24px',
    },
  },
};

const checkboxTheme = {
  baseStyle: {
    control: {
      border: '1px solid',
      borderRadius: '4px',
      borderColor: 'gray.c500',
      '&[data-checked]': {
        borderColor: COLORS.FINCH.PURPLE,
        background: COLORS.FINCH.PURPLE,
        ':hover': {
          background: '#333dbd',
          borderColor: '#333dbd',
        },
      },
    },
  },
};

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(switchAnatomy.keys);

const baseStyle = definePartsStyle({
  track: {
    bg: 'gray.c500',
    _checked: {
      bg: 'success.600',
    },
  },
});

export const switchTheme = defineMultiStyleConfig({
  baseStyle,
  sizes: {
    md: {
      thumb: {
        width: '20px',
        height: '20px',
        _checked: {
          transform: 'translateX(16px)',
        },
      },
      container: {
        width: '36px',
        height: '24px',
      },
      track: {
        width: '36px',
        height: '20px',
      },
    },
  },
});

const inputTheme = {
  baseStyle: ({ isInvalid }: { isInvalid?: boolean }) => {
    // This does end up getting called inside a function component, but the linter rule doesn't know that
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const formControl = useFormControlContext();

    return {
      field: {
        backgroundColor: 'white',
        border: '1px solid',
        borderColor: 'gray.c400',
        color: 'gray.c900',
        outlineOffset: 'unset',
        transition:
          'background-color 0.2s ease-out, color 0.2s ease-out, outline 0.2s ease-out, border-color 0.2s ease-out',
        ':focus': {
          borderColor: 'primary.c500',
          outline: '4px solid #EAEBFF',
        },
        ':disabled': {
          backgroundColor: 'gray.c100',
          color: 'gray.c600',
        },
        ...(formControl && (formControl.isInvalid || isInvalid)
          ? {
              borderColor: 'error.c300',
              ':focus': {
                outline: '4px solid #FEE4E2',
              },
            }
          : {}),
      },
    };
  },
  sizes: {
    sm: {
      field: {
        borderRadius: '8px',
      },
    },
  },
  defaultProps: {
    // https://github.com/chakra-ui/chakra-ui/issues/2347#issuecomment-958699967
    variant: null,
  },
};

export const bodyFont = 'Inter';
export const headingFont = 'Inter';

const {
  definePartsStyle: defineAlertPartsStyle,
  defineMultiStyleConfig: defineAlertMultiStyleConfig,
} = createMultiStyleConfigHelpers(alertAnatomy.keys);

const finchAlertVariant = defineAlertPartsStyle({
  container: {
    border: '1px solid',
    borderRadius: '8px',
    borderColor: '#b7bcfd',
    background: '#eff1ff',
  },
  title: {
    color: COLORS.FINCH.PURPLE,
  },
  description: {
    color: COLORS.FINCH.PURPLE,
  },
  icon: {
    color: COLORS.FINCH.PURPLE,
  },
});

const finchWarningAlertVariant = defineAlertPartsStyle({
  container: {
    border: '1px solid',
    borderRadius: '8px',
    borderColor: COLORS.YELLOW_DARK,
    background: COLORS.YELLOW,
  },
  title: {
    color: COLORS.ORANGE,
  },
  description: {
    color: COLORS.ORANGE,
  },
  icon: {
    color: COLORS.ORANGE,
  },
});

export const alertTheme = defineAlertMultiStyleConfig({
  variants: {
    finch: finchAlertVariant,
    finchWarning: finchWarningAlertVariant,
  },
});

const {
  definePartsStyle: defineTagPartsStyle,
  defineMultiStyleConfig: defineTagMultiStyleConfig,
} = createMultiStyleConfigHelpers(tagAnatomy.keys);

const successTagVariant = defineTagPartsStyle({
  container: {
    bg: '#ecfdf3',
    color: '#027a48',
    border: '1px solid #a6f4c5',
    lineHeight: 1.3,
  },
});

const dangerTagVariant = defineTagPartsStyle({
  container: {
    bg: '#fef3f2',
    color: '#b42318',
    border: '1px solid #fda29b',
    lineHeight: 1.3,
  },
});

const infoTagVariant = defineTagPartsStyle({
  container: {
    bg: COLORS.GRAY.GRAY_200,
    color: COLORS.FINCH.BLACK,
    fontWeight: '400',
    borderRadius: '4px',
  },
});

export const tagTheme = defineTagMultiStyleConfig({
  variants: {
    success: successTagVariant,
    danger: dangerTagVariant,
    info: infoTagVariant,
  },
});

const {
  definePartsStyle: defineRadioPartsStyle,
  defineMultiStyleConfig: defineRadioMultiStyleConfig,
} = createMultiStyleConfigHelpers(radioAnatomy.keys);

const outline = defineRadioPartsStyle({
  control: {
    border: '2px solid',
    borderColor: 'gray.500',
    _checked: {
      border: '2px solid',
      color: COLORS.FINCH.PURPLE,
      bg: COLORS.WHITE,
      _before: {
        color: COLORS.FINCH.PURPLE,
      },
    },
  },
});

export const radioTheme = defineRadioMultiStyleConfig({
  variants: { outline },
});

const {
  definePartsStyle: defineMenuPartsStyle,
  defineMultiStyleConfig: defineMenuMultiStyleConfig,
} = createMultiStyleConfigHelpers(menuAnatomy.keys);

const menuBaseStyles = defineMenuPartsStyle({
  list: {
    p: '4px',
    borderColor: COLORS.GRAY.GRAY_200,
  },
  item: {
    fontSize: '14px',
    color: COLORS.FINCH.BLACK,
    borderRadius: '4px',
    _focus: {
      bg: COLORS.GRAY.GRAY_200,
    },
    _hover: {
      bg: COLORS.GRAY.GRAY_200,
    },
  },
});

const menuTheme = defineMenuMultiStyleConfig({
  baseStyle: menuBaseStyles,
});

export const theme = extendTheme({
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },

  fonts: {
    heading: headingFont,
    body: bodyFont,
  },

  colors: {
    primary: {
      // TODO: @aren55555: deprecate all the values prefixed with "c", need to
      //                   ask @lewisl9029 why he did this in case there was a
      //                   reason. Chakra docs recommended the numeric key
      //                   approach.
      25: '#F5F6FF',
      c25: '#F5F6FF',
      50: '#EFF1FF',
      c50: '#EFF1FF',
      100: '#EAEBFF',
      c100: '#EAEBFF',
      200: '#DADDFE',
      c200: '#DADDFE',
      300: '#B7BCFD',
      c300: '#B7BCFD',
      400: '#727BF5',
      c400: '#727BF5',
      500: '#3E4AE7',
      c500: '#3E4AE7',
      600: '#1421D1',
      c600: '#1421D1',
      700: '#0914B3',
      c700: '#0914B3',
      800: '#030D8C',
      c800: '#030D8C',
      900: '#000661',
      c900: '#000661',
    },
    gray: {
      c25: '#FCFCFC',
      c50: '#FAFAFA',
      c100: '#F7F7F7',
      c200: '#F4F4F4',
      c300: '#EDEDED',
      c400: '#D9D9D9',
      500: '#999999',
      c500: '#999999',
      c600: COLORS.GRAY.GRAY_600,
      c700: '#585858',
      c800: COLORS.GRAY.GRAY_800,
      c900: COLORS.FINCH.BLACK,
    },
    error: {
      c25: '#FFFBFA',
      50: '#FCEAE9',
      c50: '#FEF3F2',
      100: '#F6C5C1',
      c100: '#FEE4E2',
      200: '#F09F99',
      c200: '#FECDCA',
      300: '#EA7971',
      c300: '#FDA29B',
      400: '#E45449',
      c400: '#F97066',
      500: '#D92D20',
      c500: '#F04438',
      600: '#B2251A',
      c600: '#D92D20',
      700: '#851C14',
      c700: '#B42318',
      800: '#59120D',
      c800: '#912018',
      900: '#2C0907',
      c900: '#7A271A',
    },
    warning: {
      25: '#FFFCF5',
      c25: '#FFFCF5',
      50: '#FFFAEB',
      c50: '#FFFAEB',
      100: '#FEF0C7',
      c100: '#FEF0C7',
      200: '#FEDF89',
      c200: '#FEDF89',
      300: '#FEC84B',
      c300: '#FEC84B',
      400: '#FDB022',
      c400: '#FDB022',
      500: '#F79009',
      c500: '#F79009',
      600: '#DC6803',
      c600: '#DC6803',
      700: '#B54708',
      c700: '#B54708',
      800: '#93370D',
      c800: '#93370D',
      900: '#7A2E0E',
      c900: '#7A2E0E',
    },
    success: {
      25: '#F6FEF9',
      c25: '#F6FEF9',
      50: '#ECFDF3',
      c50: '#ECFDF3',
      100: '#D1FADF',
      c100: '#D1FADF',
      200: '#A6F4C5',
      c200: '#A6F4C5',
      300: '#6CE9A6',
      c300: '#6CE9A6',
      400: '#32D583',
      c400: '#32D583',
      500: '#12B76A',
      c500: '#12B76A',
      600: '#039855',
      c600: '#039855',
      700: '#027A48',
      c700: '#027A48',
      800: '#05603A',
      c800: '#05603A',
      900: '#054F31',
      c900: '#054F31',
    },
    // below should be considered legacy
    brand: {
      // TODO: remove once fully migrated, closest in new system seems to be primary.c700
      purple: '#3E4AE7',
    },
    border: {
      // TODO: remove once fully migrated, closest in new system seems to be gray.c100
      gray: '#F2F4F8',
    },
  },
  components: {
    Alert: alertTheme,
    Button: {
      sizes: {
        sm: {
          paddingInlineStart: '14px',
          paddingInlineEnd: '14px',
          paddingBlockStart: '8px',
          paddingBlockEnd: '8px',
          borderRadius: 8,
          fontSize: 14,
          lineHeight: '20px',
        },
        md: {
          paddingInlineStart: '18px',
          paddingInlineEnd: '18px',
          paddingBlockStart: '10px',
          paddingBlockEnd: '10px',
          borderRadius: 8,
          fontSize: 14,
          lineHeight: '26px',
          fontWeight: '500',
        },
      },
      variants: {
        plainIcon: {
          ':hover': {
            background: 'rgb(30 30 30 / 25%)',
          },
          ':active': {
            background: 'rgb(30 30 30 / 30%)',
          },
        },
        grayText: {
          color: COLORS.FINCH.BLACK,
          fontWeight: '500',
          ':hover': {
            color: COLORS.FINCH.PURPLE,
          },
          ':active': {
            color: COLORS.FINCH.PURPLE,
          },
        },
        primaryPurple: {
          backgroundColor: COLORS.FINCH.PURPLE,
          color: 'white',
          fontWeight: '500',
          ':hover': {
            backgroundColor: '#333dbd',
          },
          ':active': {
            backgroundColor: 'black',
          },
          ':disabled': {
            bg: COLORS.GRAY.GRAY_600,
          },
          ':disabled:hover': {
            bg: COLORS.GRAY.GRAY_600,
          },
          '&[data-loading]:hover': {
            backgroundColor: 'gray.c800',
            borderColor: 'gray.c800',
          },
        },
        primary: {
          backgroundColor: 'gray.c900',
          color: 'white',
          // 1px border with same color as background is for layout consistency with other variants that do have borders
          border: '1px solid',
          borderColor: 'gray.c900',
          transition:
            'background-color 0.2s ease-out, color 0.2s ease-out, outline 0.2s ease-out, border-color 0.2s ease-out',
          ':hover': {
            backgroundColor: 'primary.c500',
            borderColor: 'primary.c500',
          },
          ':active': {
            backgroundColor: 'primary.c500',
            borderColor: 'primary.c500',
            outline: '4px solid #EAEBFF',
            outlineOffset: 'unset',
          },
          ':disabled': {
            opacity: 0.2,
          },
          // Chakra applies a explicit style here that we need to override here
          ':disabled:hover': {
            backgroundColor: 'gray.c900',
            borderColor: 'gray.c900',
          },
          '&[data-loading]': {
            backgroundColor: 'gray.c800',
            borderColor: 'gray.c800',
          },
          '&[data-loading]:hover': {
            backgroundColor: 'gray.c800',
            borderColor: 'gray.c800',
          },
        },
        secondary: {
          backgroundColor: 'white',
          color: 'gray.c900',
          border: '1px solid',
          borderColor: 'gray.c400',
          transition:
            'background-color 0.2s ease-out, color 0.2s ease-out, outline 0.2s ease-out, border-color 0.2s ease-out',
          ':hover': {
            backgroundColor: 'gray.c200',
          },
          ':active': {
            backgroundColor: 'white',
            outline: '4px solid #F4F4F4',
            outlineOffset: 'unset',
          },
          ':disabled': {
            color: 'gray.c600',
            borderColor: 'gray.c400',
          },
          // Chakra applies a explicit style here that we need to override here
          ':disabled:hover': {
            color: 'gray.c500',
            borderColor: 'gray.c400',
          },
        },
        danger: {
          backgroundColor: 'error.c600',
          color: 'white',
          border: '1px solid',
          borderColor: 'error.c600',
          transition:
            'background-color 0.2s ease-out, color 0.2s ease-out, outline 0.2s ease-out, border-color 0.2s ease-out',
          ':hover': {
            backgroundColor: 'error.c700',
            borderColor: 'error.c700',
          },
          ':active': {
            backgroundColor: 'error.c600',
            outline: '4px solid #FEE4E2',
            outlineOffset: 'unset',
          },
          ':disabled': {
            backgroundColor: 'gray.c400',
            borderColor: 'gray.c400',
          },
          ':disabled:hover': {
            backgroundColor: 'gray.c400',
            borderColor: 'gray.c400',
          },
        },
        text: {
          backgroundColor: 'unset',
          color: 'primary.c500',
          border: '1px solid',
          borderColor: 'transparent',
          transition:
            'background-color 0.2s ease-out, color 0.2s ease-out, outline 0.2s ease-out, border-color 0.2s ease-out',
          ':hover': {
            backgroundColor: 'primary.c50',
            borderColor: 'primary.c50',
          },
          ':active': {
            backgroundColor: 'unset',
            borderColor: 'transparent',
          },
          ':disabled': {
            backgroundColor: 'unset',
            color: 'gray.c400',
          },
          // Chakra applies a explicit style here that we need to override here
          ':disabled:hover': {
            backgroundColor: 'unset',
            color: 'gray.c400',
          },
        },
        // Need to opt-in to icon variant when using IconButton
        // Can't theme IconButton separately as it's just extending Button under the hood:
        // https://github.com/chakra-ui/chakra-ui/blob/main/packages/button/src/icon-button.tsx#L48
        icon: (props: { transparentHoverBackground?: boolean }) => ({
          backgroundColor: 'unset',
          color: 'gray.c600',
          border: '1px solid',
          borderColor: 'transparent',
          transition:
            'background-color 0.2s ease-out, color 0.2s ease-out, box-shadow 0.2s ease-out, border-color 0.2s ease-out',
          ':hover': {
            backgroundColor: props.transparentHoverBackground
              ? 'unset'
              : 'gray.c100',
            borderColor: props.transparentHoverBackground
              ? 'transparent'
              : 'gray.c100',
          },
          ':active': {},
          ':disabled': {
            color: 'gray.c400',
            backgroundColor: 'unset',
          },
          // Chakra applies a explicit style here that we need to override here
          ':disabled:hover': {
            color: 'gray.c400',
            backgroundColor: 'unset',
          },
        }),
        link: (props: StyleFunctionProps) => ({
          color:
            props.colorMode === 'dark'
              ? COLORS.FINCH.INDIGO_DARK
              : COLORS.FINCH.PURPLE,
          fontWeight: '500',
          ':hover': {
            color:
              props.colorMode === 'dark' ? COLORS.FINCH.INDIGO_DARK : '#333dbd',
          },
          ':active': {
            color:
              props.colorMode === 'dark' ? COLORS.FINCH.INDIGO_DARK : '#333dbd',
          },
        }),
      },
    },
    Drawer: {
      parts: ['dialog', 'header', 'body'],
      variants: {
        secondary: {
          dialog: {
            maxW: SIZES.drawer,
          },
        },
      },
    },
    Input: inputTheme,
    Select: inputTheme,
    Switch: switchTheme,
    Radio: radioTheme,
    Divider: {
      baseStyle: {
        borderColor: 'gray.c400',
      },
    },
    Checkbox: checkboxTheme,
    Modal: ModalTheme,
    Menu: menuTheme,
    Tag: tagTheme,
    Tooltip: tooltipTheme,
  },
});
