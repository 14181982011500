import get from 'lodash/get';
import { Path } from 'react-hook-form';

export const mapDataToExportData = <
  Data extends Record<string, unknown>,
  Map extends Record<string, Path<Data>>,
>(
  data: Data[],
  map: Map,
): Record<keyof Map, string>[] => {
  return data.map((row) =>
    Object.entries(map).reduce(
      (allData, [key, value]) => ({ ...allData, [key]: get(row, value) }),
      {} as Record<keyof Map, string>,
    ),
  );
};

export const exportCSV = <
  Data extends Record<string, string>,
  Headers extends string,
>({
  data,
  headers,
  fileName,
}: {
  data: Data[];
  headers: Headers[];
  fileName: string;
}) => {
  // Convert the data array into a CSV string
  const csvString = [
    headers,
    ...data.map((item) =>
      headers.map((header) => {
        const value = item[header];
        return Array.isArray(value)
          ? `"${value.join(',')}"`
          : typeof value === 'string' && value.includes(',')
          ? `"${value}"`
          : value;
      }),
    ),
  ]
    .map((row) => row.join(','))
    .join('\n');

  // Create a Blob from the CSV string
  const blob = new Blob([csvString], { type: 'text/csv' });

  // Generate a download link and initiate the download
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = `${fileName}.csv`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};
