'use client';

import {
  Alert,
  AlertIcon,
  Button,
  Center,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';

import { useProxyToApiServer } from '../hooks/use-proxy-to-api-server';
import { useConnectionsApiSchema } from '../hooks/use-api-schema';
import { OperationsList } from '../components/operations-list';
import { ContentBox } from 'components/ContentBox';
import { Request } from '../components/request';
import { Response } from '../components/response';
import { useSelectedOperation } from '../store/selected-operation';

export const ApiExplorer = () => {
  const {
    isLoading: isSchemaLoading,
    error: schemaError,
    refetch: refetchApiSchema,
  } = useConnectionsApiSchema();

  const methods = useForm();

  const selectedOperation = useSelectedOperation(
    (state) => state.selectedOperation,
  );

  const { makeRequest, requestId, serverResponse, isFetching, error } =
    useProxyToApiServer({ formContext: methods });

  const isUnauthorized = schemaError?.message.includes('401');

  if (schemaError) {
    return (
      <Alert status="error">
        <AlertIcon />
        <Text>
          {isUnauthorized
            ? 'Admin access is required for the API Explorer. Please reach out to an admin on your team and ask them to change your role to enable this feature.'
            : 'Something went wrong'}
        </Text>
        {!isUnauthorized && (
          <Button
            onClick={() => refetchApiSchema()}
            size="sm"
            ml="auto"
            variant="solid"
            colorScheme="blackAlpha"
          >
            Retry
          </Button>
        )}
      </Alert>
    );
  }

  return isSchemaLoading ? (
    <Center flex="1" h="50vh">
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
      />
    </Center>
  ) : (
    <FormProvider {...methods}>
      <Stack
        direction={{ base: 'column', lg: 'row' }}
        spacing="24px"
        align={{ base: 'stretch', lg: 'flex-start' }}
        h={{ base: 'full', lg: 'calc(100vh - 420px)' }}
      >
        <ContentBox
          w={{ base: 'full', lg: '275px' }}
          p="2"
          overflowY="auto"
          maxH={{ base: '250px', lg: '100%' }}
          minH="200px"
        >
          <OperationsList />
        </ContentBox>
        {selectedOperation ? (
          <>
            <ContentBox
              overflowY="auto"
              flex="1"
              maxH={{ base: '250px', lg: '100%' }}
              minH="150px"
            >
              <Request
                fetch={makeRequest}
                isLoading={isFetching}
                error={error}
              />
            </ContentBox>
            <ContentBox
              p="4"
              flex="1"
              minH="400px"
              display="flex"
              overflowY="auto"
              maxH="100%"
              flexDirection="column"
            >
              <Response serverResponse={serverResponse} requestId={requestId} />
            </ContentBox>
          </>
        ) : (
          <Center h="100%">
            <Text>Select an operation to view the request and response</Text>
          </Center>
        )}
      </Stack>
    </FormProvider>
  );
};
