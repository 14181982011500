import { useQuery } from '@tanstack/react-query';
import { useConnectionDetailApi } from './useConnectionDetailApi';

export const useConnectionPayStatementItem = ({
  applicationId,
  connectionId,
  enabled,
  options = {},
}: {
  applicationId: string;
  connectionId: string;
  enabled: boolean;
  options?: { startDate?: Date };
}) => {
  const api = useConnectionDetailApi();

  const { isLoading, data = null } = useQuery({
    queryKey: [
      'connection-pay-statement-item',
      applicationId,
      connectionId,
      options.startDate,
    ],
    queryFn: () => {
      return api.payStatementItem(applicationId, connectionId, options);
    },
    enabled,
  });

  return { payStatementItem: data || [], isLoading };
};
