import { Box } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import { ConnectionDetailScreen } from '../components/ConnectionDetailScreen';
import { PageContainer } from '../../components/PageContainer';
import { useConnectionConfiguration } from '../hooks/useConnectionConfiguration';

export const ConnectionDetail = () => {
  const { applicationId, connectionId } = useParams<{
    connectionId: string;
    applicationId: string;
  }>();
  const { connectionConfiguration, isLoading: isConfigurationLoading } =
    useConnectionConfiguration({
      applicationId,
      connectionId,
      thirdParty: 'pensionPro',
    });

  return (
    <Box>
      <PageContainer>
        {!isConfigurationLoading && connectionConfiguration && (
          <ConnectionDetailScreen
            applicationId={applicationId}
            connectionId={connectionId}
            connectionConfiguration={connectionConfiguration}
          />
        )}
      </PageContainer>
    </Box>
  );
};
