import styled from '@emotion/styled';
import React, {
  ForwardedRef,
  DetailedHTMLProps,
  ReactNode,
  forwardRef,
} from 'react';
import { COLORS } from '../../constant';

const colorTheme = {
  warning: {
    textColor: COLORS.ORANGE,
    borderColor: COLORS.YELLOW_DARK,
    backgroundColor: COLORS.YELLOW,
  },
  error: {
    textColor: COLORS.FINCH.ERROR_RED,
    borderColor: COLORS.FINCH.BORDER_RED,
    backgroundColor: COLORS.LIGHT_RED,
  },
} as const;

interface StyledTextProps
  extends DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  type: 'warning' | 'error';
  showEntireText?: boolean;
}

const Text = styled.div<StyledTextProps>`
  width: 100%;
  font-weight: 500;
  font-size: 13px;
  line-height: 21px;
  cursor: inherit;
  color: ${(props) => colorTheme[props.type].textColor};
  white-space: ${(props) => (props.showEntireText ? 'unset' : 'nowrap')};
  overflow: ${(props) => (props.showEntireText ? 'unset' : 'hidden')};
  text-overflow: ${(props) => (props.showEntireText ? 'unset' : 'ellipsis')};
  letter-spacing: 0em;
  text-align: left;
`;

interface StyledBannerProps
  extends DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  type: 'warning' | 'error';
  tooltip?: boolean;
  fullWidth?: boolean;
  showEntireText?: boolean;
}

const StyledBadge = styled.div<StyledBannerProps>`
  height: ${(props) => (props.showEntireText ? 'auto' : '40px')};
  width: ${(props) => (props.fullWidth ? '100%' : 'fit-content')};
  padding: 8px;
  border-radius: 8px;
  border-width: 1px;
  border-color: ${(props) => colorTheme[props.type].borderColor};
  display: flex;
  justify-content: center;
  align-items: flex-start;
  cursor: default;
  background-color: ${(props) => colorTheme[props.type].backgroundColor};
  color: ${(props) => colorTheme[props.type].textColor}
    ${(props) => props.tooltip && 'cursor: pointer;'};
`;

const CustomizedIcon = styled.img`
  height: 20px;
  width: 20px;
  margin-right: 6px;
`;

export const Banner = forwardRef(
  (
    {
      type = 'warning',
      icon,
      content,
      tooltip,
      fullWidth = false,
      showEntireText = false,
    }: {
      type?: 'warning' | 'error';
      icon: string;
      content: ReactNode;
      tooltip?: boolean;
      fullWidth?: boolean;
      showEntireText?: boolean;
    },
    ref: ForwardedRef<HTMLDivElement>,
  ) => (
    <StyledBadge
      ref={ref}
      type={type}
      tooltip={tooltip}
      fullWidth={fullWidth}
      showEntireText={showEntireText}
    >
      <CustomizedIcon src={icon} />
      <Text type={type} showEntireText={showEntireText}>
        {content}
      </Text>
    </StyledBadge>
  ),
);

Banner.displayName = 'Banner';
