import { HStack, IconButton, Stack, Text } from '@chakra-ui/react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { ProviderEntryProps } from '../types';
import { ProviderIcon } from '../../components/Iconagraphy/ProviderIcon';
import { Switch } from '../../components/Switch';
import { PinProvider } from './PinProvider';
import { DragIndicator } from '../../shared/icons/DragIndicator';
import { ContentBox } from '../../components/ContentBox';
import { COLORS, Z_INDEX } from '../../constant';
import { EditButton } from './EditButton';
import { ConfigurationNeededToolTip } from './ConfigurationNeededToolTip';
import { getConfigurationNeededText, isProviderEnabled } from '../utils';
import { UnsavedConfigIndicator } from '../../shared/icons/UnsavedConfigIndicator';
import { usePermissions } from '../../hooks/use-permissions';
import { PERMISSION } from '../../constant/roles-and-permissions';
import { useApplication } from 'applications/hooks';

export const SortableProviderEntry = ({
  provider,
  providerSettings,
  updateIsPinned,
  updateEnabled,
  isLastItem,
  setSelectedProviderId,
  hasStagedConfiguration,
  canUpdate,
}: ProviderEntryProps) => {
  const { application } = useApplication();

  const {
    attributes,
    listeners,
    isDragging,
    setNodeRef,
    transform,
    transition,
    setActivatorNodeRef,
  } = useSortable({ id: provider.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? Z_INDEX.FOREGROUND : Z_INDEX.BACKGROUND,
  };

  const configurationNeededText = getConfigurationNeededText({
    providerSettings,
    providerWithImplementation: provider,
    isSandbox: application?.isSandbox() ?? false,
  });

  const providerEnabled = isProviderEnabled(provider, canUpdate);

  const roleCanUpdateConfigurations = usePermissions([
    PERMISSION.IntegrationConfigurations.Update,
  ]);

  return (
    <ContentBox
      key={provider.id}
      as="li"
      sx={{ listStyle: 'none' }}
      borderRadius="0"
      p="16px 22px"
      aria-label={provider.displayName}
      mb={isLastItem ? '120px' : '0'}
      borderBottomRadius={isLastItem ? '8px' : '0'}
      passedRef={setNodeRef}
      style={style}
    >
      <Stack direction={{ base: 'column', lg: 'row' }} justify="space-between">
        <HStack opacity={providerEnabled ? 1 : 0.3}>
          <IconButton
            aria-label={`${provider.displayName} drag indicator`}
            icon={<DragIndicator />}
            variant="ghost"
            minW="fit-content"
            cursor="grab"
            isDisabled={!roleCanUpdateConfigurations}
            _hover={{ backgroundColor: COLORS.GRAY.GRAY_100 }}
            ref={setActivatorNodeRef}
            {...listeners}
            {...attributes}
          />

          <PinProvider
            provider={provider}
            updateIsPinned={updateIsPinned}
            enabled={providerEnabled && roleCanUpdateConfigurations}
            disableTooltip={!providerEnabled}
          />

          <ProviderIcon
            size="lg"
            src={provider.icon}
            provider={provider.displayName}
          />
          <Text>{provider.displayName}</Text>
          {hasStagedConfiguration(provider.id) && <UnsavedConfigIndicator />}
        </HStack>
        <HStack justify="flex-end" spacing="16px">
          {configurationNeededText && (
            <ConfigurationNeededToolTip
              text={configurationNeededText.tooltip}
            />
          )}
          <Switch
            id={provider.id}
            enabled={providerEnabled}
            onChange={() => updateEnabled(provider.id, !provider.enabled)}
            isDisabled={!roleCanUpdateConfigurations || !canUpdate}
          />

          {roleCanUpdateConfigurations && (
            <EditButton onClick={() => setSelectedProviderId(provider.id)} />
          )}
        </HStack>
      </Stack>
    </ContentBox>
  );
};
