import noop from 'lodash/noop';
import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Spacer,
  Switch,
  Text,
} from '@chakra-ui/react';

import { ChevronDownIcon } from '../../../../shared/icons/ChevronDownIcon';
import { CancelXIcon } from '../../../../shared/icons/CancelXIcon';
import { RightArrowIcon } from '../../../../shared/icons/RightArrowIcon';
import { CheckIcon } from '../../../../shared/icons/CheckIcon';
import { COLORS, Z_INDEX } from '../../../../constant';
import { Category, Label, Option } from '../types';

export function PayStatementCategoryRow({
  category,
  name,
  value,
  options,
  checked,
  editing,
  disabled,
  onChange,
  onToggle,
}: {
  editing: boolean;
  disabled: boolean;
  name: string;
  category: Category;
  options: Option[];
  value?: string;
  onChange: (value: string | string[]) => void;
  checked?: boolean;
  onToggle?: (polarity: boolean) => void;
}) {
  const selected = value
    ? options.find((x) => x.value === value)?.value
    : 'Choose label';
  const active = editing && !disabled;

  return (
    <Box>
      <Divider />
      <HStack paddingX="24px" paddingY="12px" gap="32px">
        <Box width="100%" maxWidth="580px">
          <Flex width="100%" maxWidth="580px">
            <Text>{name}</Text>
            <Spacer />
            <Box>
              <RightArrowIcon />
            </Box>
          </Flex>
        </Box>
        <Box width="100%">
          <Flex>
            <Menu variant="secondary" placement="left-start">
              <MenuButton
                as={Button}
                borderRadius="lg"
                onClick={noop}
                border="1px solid"
                borderColor={COLORS.GRAY.GRAY_400}
                background={active ? COLORS.WHITE : COLORS.GRAY.GRAY_200}
                padding="10px"
                width="100%"
                rightIcon={<ChevronDownIcon />}
                cursor={active ? undefined : 'not-allowed'}
                _active={{ background: COLORS.GRAY.GRAY_200 }}
                _hover={{ textDecoration: 'unset' }}
                color={COLORS.GRAY.GRAY_600}
                fontSize="14px"
                textAlign="left"
              >
                {selected}
              </MenuButton>
              {active ? (
                <MenuList
                  padding="4px"
                  zIndex={Z_INDEX.MENU}
                  background={COLORS.WHITE}
                >
                  <MenuOptionGroup type="radio" onChange={onChange}>
                    {options.map(({ key, value }) => (
                      <MenuItemOption
                        key={key}
                        value={value}
                        icon={null}
                        width="100%"
                        borderRadius="sm"
                        background={COLORS.WHITE}
                        _focus={{ background: COLORS.GRAY.GRAY_200 }}
                        _selected={{ background: COLORS.GRAY.GRAY_200 }}
                        _hover={{ background: COLORS.GRAY.GRAY_200 }}
                      >
                        <HStack>
                          <Text
                            color={
                              key === 'none'
                                ? COLORS.GRAY.GRAY_500
                                : COLORS.FINCH.BLACK
                            }
                          >
                            {key === 'none' ? <em>None</em> : value}
                          </Text>
                          <Spacer />
                          {value === selected ? <CheckIcon /> : null}
                        </HStack>
                      </MenuItemOption>
                    ))}
                  </MenuOptionGroup>
                </MenuList>
              ) : null}
            </Menu>
          </Flex>
        </Box>
        {onToggle && (checked !== undefined || editing) ? (
          <Box minWidth="73px">
            <HStack gap="8px">
              {editing ? (
                <Switch
                  isChecked={checked}
                  defaultChecked={true}
                  onChange={(e) => onToggle(e.target.checked)}
                  disabled={!active}
                />
              ) : (
                <Box
                  minWidth="24px"
                  width="24px"
                  height="24px"
                  backgroundColor={
                    checked ? COLORS.LIGHT_GREEN : COLORS.GRAY.GRAY_200
                  }
                  borderRadius="6px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  {checked ? (
                    <CheckIcon color={COLORS.GREEN} />
                  ) : (
                    <CancelXIcon />
                  )}
                </Box>
              )}
              <Text
                color={COLORS.GRAY.GRAY_400}
                fontSize="14px"
                fontWeight={500}
              >
                {checked ? 'Yes' : 'No'}
              </Text>
            </HStack>
          </Box>
        ) : onToggle ? (
          <Box minWidth="73px"></Box>
        ) : null}
      </HStack>
    </Box>
  );
}
