import type { ValueOf } from 'type-fest';
import { Button, ModalFooter, ModalBody, Text, Stack } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import errorIcon from 'assets/images/icons/error_warning_red.svg';
import { COLORS } from 'constant';
import { CsvFileUpload } from 'components/CsvFileUpload';
import { FileDownloadIcon } from 'shared/icons/FileDownloadIcon';
import { FormSelect } from 'components/FormSelect';
import { useApplication } from 'applications/hooks';
import { PRODUCT_LABEL_MAP } from 'constant/scopes';
import startCase from 'lodash/startCase';
import { Banner } from 'components/Banner/Banner';
import { useCsvFileUpload } from 'connections-v2/hooks/useCsvFileUpload';
import {
  CreateBulkConnectSessionResponse,
  csvRowSchema,
  NewBulkSession,
} from 'connections-v2/hooks/useBulkConnectSessions';
import { AxiosError } from 'axios';
import { UseMutateFunction } from '@tanstack/react-query';

const CsvRowMap = {
  'Customer ID': 'customerId',
  'Customer Name': 'customerName',
} as const;

const generateSampleCsv = () => {
  const csvContent = [
    ['Customer ID', 'Customer Name', 'Customer Email'],
    ['', '', ''],
  ]
    .map((row) => row.join(','))
    .join('\n');

  const blob = new Blob([csvContent], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = 'sample_list_of_customers.csv';
  link.click();
  window.URL.revokeObjectURL(url);
};

export const EmployerCsvUploadForm = ({
  mutate,
  isPending,
}: {
  mutate: UseMutateFunction<
    CreateBulkConnectSessionResponse,
    AxiosError,
    NewBulkSession
  >;
  isPending: boolean;
}) => {
  const form = useForm();
  const { application } = useApplication();

  const allScopes =
    application?.scopes
      .filter(({ name }) => name && PRODUCT_LABEL_MAP.get(name))
      .map(({ name }) => ({
        value: name,
        label: PRODUCT_LABEL_MAP.get(name) || startCase(name),
      })) || [];

  const { error, csvFile, dropzone, removeFile, jsonData } = useCsvFileUpload<
    Record<ValueOf<typeof CsvRowMap>, string>
  >({
    headerMap: CsvRowMap,
    validations: [
      (rows) => {
        if (rows.length > 500) {
          return 'There are more than 500 records in the uploaded CSV. Please upload a file with no more than 500 records.';
        }

        return null;
      },
      (rows) => {
        if (
          !Object.values(CsvRowMap).every((column) =>
            rows.every((row) => column in row),
          )
        ) {
          return 'The CSV file must contain the columns "Customer ID", "Customer Name"';
        }

        return null;
      },
      (rows) => {
        if (new Set(rows.map((row) => row.customerId)).size !== rows.length) {
          return 'There are one or more records with duplicate customer IDs. Each record must have a unique identifier. Please double-check your file and try again.';
        }

        return null;
      },
      (rows) => {
        for (let index = 0; index < rows.length; index++) {
          const row = rows[index];
          const isRowValid = csvRowSchema.safeParse(row);

          if (!isRowValid.success) {
            return `Row ${
              index + 1
            } has for following errors: ${isRowValid.error.issues
              .map((issue) => issue.message)
              .join(', ')}`;
          }
        }

        return null;
      },
    ],
  });

  const scopes = form.watch('scopes');
  const isFormValid = !!scopes?.length && !!jsonData?.length;

  return (
    <>
      <ModalBody zIndex={1} px="0" pt="6">
        <Stack spacing={8}>
          <Stack spacing={4}>
            {error && (
              <Banner
                icon={errorIcon}
                type="error"
                content={error}
                fullWidth
                showEntireText
              />
            )}
            <Stack spacing={1}>
              <Text fontSize="14px" fontWeight="500">
                Upload a CSV file for employers
              </Text>
              <Text color={COLORS.GRAY.GRAY_600} fontSize="14px">
                Upload a list of up to 500 employers with unique customer IDs
              </Text>
            </Stack>
            <CsvFileUpload
              csvFile={csvFile}
              dropzone={dropzone}
              removeFile={removeFile}
            />
            {!csvFile && (
              <Button
                variant="link"
                size="sm"
                leftIcon={<FileDownloadIcon />}
                justifyContent="flex-start"
                fontSize="13px"
                fontWeight="400"
                color={COLORS.GRAY.GRAY_600}
                _hover={{
                  color: COLORS.GRAY.GRAY_600,
                  textDecoration: 'underline',
                }}
                onClick={generateSampleCsv}
              >
                Download a CSV sample file
              </Button>
            )}
          </Stack>
          <FormSelect
            id="scopes"
            register={form.register}
            isMulti
            control={form.control}
            placeholder="Choose products"
            options={allScopes}
            isClearable={false}
            label="Products"
            helpText="Choose which Finch products to be included as part of the Finch Connect flow."
            defaultValue={allScopes}
          />
        </Stack>
      </ModalBody>
      <ModalFooter px="0">
        <Button
          w="full"
          variant="primary"
          isDisabled={!isFormValid}
          isLoading={isPending}
          fontSize="16px"
          onClick={() =>
            mutate({
              products: scopes,
              rows: jsonData || [],
            })
          }
          h="52px"
        >
          Create Links -&gt;
        </Button>
      </ModalFooter>
    </>
  );
};
