import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

import loading from '../../assets/images/icons/loading_light.svg';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

const spin = keyframes`
  to { transform: rotate(360deg); }
`;

const LoadingIcon = styled.img`
  width: 60px;
  animation: ${spin} 1s linear infinite;
`;

export const LoadingModal = ({
  message,
  isOpen,
}: {
  message: string;
  isOpen: boolean;
}) => {
  return (
    <Modal isCentered isOpen={isOpen} onClose={() => {}} size="md">
      <ModalOverlay />
      <ModalContent alignItems="center" padding="10">
        <LoadingIcon src={loading} alt="loading" />
        <ModalHeader fontWeight="500" textAlign="center">
          Hang tight!
        </ModalHeader>
        <ModalBody textAlign="center" paddingTop="0">
          {message}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
